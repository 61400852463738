import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '@Shared/ui';
import { useViewThePromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';

import { PersonalPromotionDetails } from './PersonalPromotionDetails';

import './styles.scss';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    promotionId: string;
};

const PromotionDetailsDrawer = ({ isDrawerOpen, setDrawerOpen, promotionId }: ProductDrawerProps) => {
    const { isFetching, data: promotion } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Информация об акции"
            className="promotion__drawer"
            styles={{
                body: {
                    paddingBottom: 0,
                },
            }}
        >
            {!!promotion && <PersonalPromotionDetails promotion={promotion} />}
        </Drawer>
    );
};

export default PromotionDetailsDrawer;
