import { DiscountTypeEnum } from '@Shared/api/rtkQueryApis/sellerPromotions';

export const discountTypeOptions = [
    {
        label: 'Проценты',
        value: DiscountTypeEnum.Percentage,
    },
    {
        label: 'RUB',
        value: DiscountTypeEnum.Fixed,
    },
];
