import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import TRANSLATIONS_EN from './translations/translation.en.json';
// import TRANSLATIONS_RU from './translations/translation.ru.json';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        keySeparator: false,
        nsSeparator: false,
        react: {
            useSuspense: false,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'b'],
        },
        returnEmptyString: false,
        resources: {
            // en: {
            //     translation: TRANSLATIONS_EN,
            // },
            // ru: {
            //     translation: TRANSLATIONS_RU,
            // },
        },
    });

export { i18n };
export default i18n;
