import { useEffect, useMemo } from 'react';
import { type SelectProps } from 'antd';

import { Select } from '@Shared/ui';
import { useGetStoresListQuery, StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

type MarketplaceStoreDropdownProps = {
    selectValue?: string;
    onChange?: (arg1: string, arg2: { mpStore: StoreDto3 }) => void;
    isDisabled?: boolean;
    selectProps?: SelectProps;
    filterOptions?: (arg1: unknown) => unknown;
    setInitialValue?: (arg1: unknown) => unknown;
};

const MarketplaceStoreDropdown = ({
    selectValue,
    onChange,
    isDisabled,
    selectProps = {},
    filterOptions,
    setInitialValue,
}: MarketplaceStoreDropdownProps) => {
    const { data: stores, isLoading } = useGetStoresListQuery();

    const allMarketplaceStores = useMemo(
        () =>
            (stores?.items || []).flatMap((store) =>
                store?.marketplaceStores?.map((marketplaceStore) => ({
                    store,
                    marketplaceSlug: marketplaceStore?.marketplace_slug,
                    value: marketplaceStore.id,
                    label: `${marketplaceStore?.marketplace_title} - ${store.title}`,
                })),
            ),
        [stores?.items],
    );

    useEffect(() => {
        if (setInitialValue) {
            setInitialValue(filterOptions ? filterOptions(allMarketplaceStores) : allMarketplaceStores);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMarketplaceStores]);

    return (
        <Select
            placeholder="Выберите связку маркетплейс - магазин"
            onChange={onChange}
            value={selectValue}
            style={{ width: '100%' }}
            options={filterOptions ? filterOptions(allMarketplaceStores) : allMarketplaceStores}
            loading={isLoading}
            disabled={isDisabled}
            {...selectProps}
        />
    );
};

export default MarketplaceStoreDropdown;
