import { theme } from 'antd';

import { PersonalPromotionsTableBlock } from '@Features/seller/promotions';
import { Space, Typography } from '@Shared/ui';

const { Text, Link } = Typography;

const PersonalPromotionsTab = () => {
    const {
        token: { fontSizeLG },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В этом разделе вы можете добавлять свои персональные предложения, которые используете на Ozon.
            </Text>
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                Сначала разместите предложение на Ozon в разделе{' '}
                <Link
                    href="https://seller.ozon.ru/app/highlights/my-highlights/list"
                    target="_blank"
                >
                    Цены и акции → Мои акции
                </Link>
                , затем добавьте его сюда.{' '}
                <Link
                    href="https://seller-edu.ozon.ru/ceny-i-akcii/akcii-skidki-i-kupony/my-promo#%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D1%82%D1%8C-%D1%81%D0%BE%D0%B1%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%83%D1%8E-%D0%B0%D0%BA%D1%86%D0%B8%D1%8E:~:text=%D0%B5%D1%91%20%D0%BD%D0%B5%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%81%D1%8F.-,%D0%9A%D0%B0%D0%BA%20%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D1%82%D1%8C%20%D1%81%D0%BE%D0%B1%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%83%D1%8E%20%D0%B0%D0%BA%D1%86%D0%B8%D1%8E,-%D0%9E%D1%82%D0%BA%D1%80%D0%BE%D0%B9%D1%82%D0%B5%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83%20%D0%A6%D0%B5%D0%BD%D1%8B"
                    target="_blank"
                >
                    {' '}
                    Как разместить предложение на Ozon?
                </Link>
            </Text>
            <PersonalPromotionsTableBlock />
        </Space>
    );
};
export default PersonalPromotionsTab;
