import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Grid } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const DeclinePromotionParticipationModal = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const promotionId = modalState?.params?.promotionId;
    const isOpen = modalState.modalKey === ModalKeysType.declinePromotionParticipation && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Отключить персональное предложение
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={
                <Row
                    justify="space-between"
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Button
                        onClick={handleCancel}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Отменить
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            // eslint-disable-next-line max-len
                            window.location.href = `mailto:${process.env.SUPPORT_EMAIL}?subject=Отключить%20акцию%20id=${promotionId}`;
                        }}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Отправить заявку
                    </Button>
                </Row>
            }
        >
            <Text>
                При отключении персонального предложения действующие паблишеры получат об этом уведомление, а новые
                паблишеры не смогут к ней подключиться. Когда менеджер проверит информацию, он отключит ваше
                персональное предложение, и мы отправим вам уведомление об этом.
            </Text>
        </Modal>
    );
};
export default DeclinePromotionParticipationModal;
