import { MarketPlaceSlugEnum4 } from '@Shared/api/rtkQueryApis/sellerStores';

import { OzonIntegrationForm, WildberriesIntegrationForm } from './marketplaceForms';
import './styles.scss';

type SetupIntegrationFormProps = {
    storeId: string;
    marketplace?: MarketPlaceSlugEnum4;
};

const SetupIntegrationForm = ({ storeId, marketplace = MarketPlaceSlugEnum4.Ozon }: SetupIntegrationFormProps) => {
    const renderForm = {
        ozon: <OzonIntegrationForm storeId={storeId} />,
        wildberries: <WildberriesIntegrationForm storeId={storeId} />,
    };

    return renderForm[marketplace];
};
export default SetupIntegrationForm;
