import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Row, Button, Grid } from '@Shared/ui';
import { type ProductCampaignDto } from '@Shared/api/rtkQueryApis/publisherSources';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { useBreakpoint } = Grid;

const TrackingLinksCell = ({ productCampaign }: { productCampaign: ProductCampaignDto }) => {
    const { copyTextToClipboard } = useClipboard();
    const breakpoints = useBreakpoint();

    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    const handleCopyText = (e: MouseEvent<HTMLElement>, link: string, message: string) => {
        e.stopPropagation();
        copyTextToClipboard(link || '', message);
    };

    return (
        <Row style={{ gap: 12 }}>
            <Button
                size="small"
                style={{ borderRadius: borderRadiusLG, width: breakpoints.sm ? 'auto' : '100%' }}
                iconPosition="end"
                disabled={!productCampaign?.tracking_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        productCampaign?.tracking_link || '',
                        'Полная партнерская ссылка успешно скопирована',
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Полная
            </Button>
            <Button
                size="small"
                iconPosition="end"
                style={{ borderRadius: borderRadiusLG, width: breakpoints.sm ? 'auto' : '100%' }}
                type="primary"
                disabled={!productCampaign?.tracking_short_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        productCampaign?.tracking_short_link || '',
                        'Короткая партнерская ссылка успешно скопирована',
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Короткая
            </Button>
        </Row>
    );
};

export default TrackingLinksCell;
