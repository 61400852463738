import { useEffect } from 'react';

import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Col, Grid, Form, Select, Input, Checkbox } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import {
    useViewAContactQuery,
    ContactTypeEnum,
    useAddANewContactMutation,
    usePutAppPrivateContactUpdateMutation,
} from '@Shared/api/rtkQueryApis/contacts';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import { contactTypesMap, contactInputPlaceholders } from '../model/types';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const AddEditContactModal = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const [form] = Form.useForm();
    const selectedContactType = Form.useWatch('type', form);
    const contactId = modalState?.params?.contactId;
    const {
        data: contact,
        isFetching,
        isSuccess,
    } = useViewAContactQuery({ contact: contactId }, { skip: !contactId, refetchOnMountOrArgChange: true });
    const [addContact, { isLoading: isAdding, error: isAddingError }] = useAddANewContactMutation();
    const [updateContact, { isLoading: isUpdating, error: isUpdatingError }] = usePutAppPrivateContactUpdateMutation();
    const isOpen = modalState.modalKey === ModalKeysType.addEditContact && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const onFinish = async (formValues) => {
        try {
            contactId
                ? await updateContact({
                      contactDto2: formValues,
                      contact: contactId,
                  }).unwrap()
                : await addContact({
                      contactDto: formValues,
                  }).unwrap();
            dispatch(
                showMessage({
                    componentProps: {
                        type: MessageType.Success,
                        content: contactId ? 'Контакт успешно обновлен' : 'Новый контакт успешно добавлен',
                    },
                }),
            );
            handleCancel();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (contact && isSuccess) {
            form.setFieldsValue(contact);
        }
    }, [form, contact, isSuccess]);

    useEffect(() => {
        if (!contactId) {
            form.resetFields();
        }
    }, [form, contactId]);

    useEffect(() => {
        const errObject = isAddingError || isUpdatingError;
        if (errObject) {
            const errors = normalizeErrorsObject(errObject?.data?.errors);
            const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
            form.setFields(formErrors);
        }
    }, [form, isAddingError, isUpdatingError]);

    const initialValues = {
        type: ContactTypeEnum.Telegram,
    };

    const contactTypeOptions = Object.values(ContactTypeEnum).map((type) => ({
        value: type,
        label: contactTypesMap[type],
    }));

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    {contactId ? 'Изменение контактных данных' : 'Добавление контактных данных'}
                </Title>
            }
            open={isOpen}
            loading={isFetching}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                name="add-edit-contact-form"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                initialValues={initialValues}
                // className="legal-info__form"
            >
                <Text>Указывайте только свои реальные данные. </Text>
                <Form.Item
                    label="Ваш аккаунт"
                    style={{ marginTop: 20, marginBottom: 0 }}
                >
                    <Row
                        // style={{ width: breakpoints.lg ? 160 : '100%', gap: 8 }}
                        style={{ gap: 10 }}
                        wrap={!breakpoints.md}
                    >
                        <Form.Item
                            name="type"
                            style={{ width: breakpoints.md ? 160 : '100%', marginBottom: 0 }}
                        >
                            <Select
                                options={contactTypeOptions}
                                disabled={contactId}
                            />
                        </Form.Item>
                        <Form.Item
                            name="value"
                            style={{ width: '100%', marginBottom: 0 }}
                        >
                            <Input placeholder={contactInputPlaceholders[selectedContactType]} />
                        </Form.Item>
                    </Row>
                </Form.Item>
                <Form.Item
                    name="primary_contact"
                    valuePropName="checked"
                >
                    <Checkbox>Отметить как предпочтительный способ связи</Checkbox>
                </Form.Item>
                <Row
                    justify="space-between"
                    wrap={false}
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Col>
                        <Button
                            onClick={handleCancel}
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                        >
                            Отменить
                        </Button>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                            // onClick={handleApprove}
                            loading={isAdding || isUpdating}
                        >
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default AddEditContactModal;
