import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector, useExport } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Col, Grid, Form, Select } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { SourceMPstoresSearchDropdown } from '@Features/shared';
import { queryParamsSerializer } from '@Shared/utils/queryParamsSerializer';
import { PaymentTypeEnum } from '@Shared/api/rtkQueryApis/publisherSources';

import { exportTypesOptions, ExportTypesEnum } from '../model/types';

const BASE_URL = process.env.SERVER_URL;

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const ExportProductsModal = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const [form] = Form.useForm();
    const selectedExportType = Form.useWatch('export_type', form);
    const selectedMarketplaceStore = Form.useWatch('marketplace_store', form);
    const sourceId = modalState?.params?.sourceId;
    const isOpen = modalState.modalKey === ModalKeysType.exportPublisherProducts && modalState.open;
    const { handleDownload, isLoading: isExporting } = useExport();

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const handleClickDownload = async () => {
        try {
            const params = queryParamsSerializer({ source: sourceId, payment_type: PaymentTypeEnum.Cpc });
            selectedExportType === 'yml'
                ? await handleDownload(
                      // eslint-disable-next-line max-len
                      `${BASE_URL}/api/publisher/v1/source/${sourceId}/marketplace-store/${selectedMarketplaceStore}/product/export/yml/?${params}`,
                      'products.yml',
                  )
                : await handleDownload(`${BASE_URL}/api/publisher/v1/product/export/?${params}`, 'products.csv');
            form.resetFields();
            handleCancel();
        } catch (err) {
            console.error(err);
        }
    };

    // useEffect(() => {
    //     if (!contactId) {
    //         form.resetFields();
    //     }
    // }, [form, contactId]);

    const initialValues = {
        export_type: ExportTypesEnum.CSV,
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Выгрузка товаров
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                name="export-products-form"
                autoComplete="off"
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleClickDownload}
            >
                <Text style={{ marginBottom: 12, display: 'inline-block' }}>
                    Выберите формат, в котором нужно скачать файл с информацией о товарах{' '}
                </Text>

                <Form.Item
                    name="export_type"
                    label="Формат файла"
                    required
                >
                    <Select options={exportTypesOptions} />
                </Form.Item>
                {selectedExportType === ExportTypesEnum.YML ? (
                    <>
                        <Text style={{ marginBottom: 12, display: 'inline-block' }}>
                            Товары какого магазина на маркетплейсе вы хотите продвигать?
                        </Text>
                        <Form.Item
                            name="marketplace_store"
                            label="Магазин на маркетплейсе"
                            rules={[{ required: true, message: 'Необходимо выбрать магазин на маркетплейсе' }]}
                        >
                            <SourceMPstoresSearchDropdown sourceId={sourceId} />
                        </Form.Item>
                    </>
                ) : null}
                <Row
                    justify="space-between"
                    wrap={false}
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Col>
                        <Button
                            onClick={handleCancel}
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                        >
                            Закрыть
                        </Button>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                            loading={isExporting}
                        >
                            Скачать
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default ExportProductsModal;
