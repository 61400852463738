import { Card, Typography, Form, Input, Grid, DatePicker, Row, Select, InputNumber, QuestionTooltip } from '@Shared/ui';
import { DEFAULT_DATE_FORMAT } from '@Shared/constants';
import { PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';

import { discountTypeOptions } from './model/types';

const { Title } = Typography;
const { TextArea } = Input;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

const PromotionDetailsCard = ({
    selectedPromotionType,
    isAddPersonalPromotion,
}: {
    selectedPromotionType: PromotionTypeEnum2;
    isAddPersonalPromotion?: boolean;
}) => {
    const breakpoints = useBreakpoint();

    return (
        <Card>
            <Title
                level={3}
                style={{ marginTop: 0 }}
            >
                Информация об акции
            </Title>
            <Form.Item
                label="Название акции"
                name="title"
                style={{ marginTop: 20 }}
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
            >
                <Input placeholder="Введите название акции" />
            </Form.Item>
            <Form.Item
                label="Условия акции"
                name="description"
                style={{ marginTop: 20 }}
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
            >
                <TextArea
                    placeholder="Укажите условия акции"
                    rows={4}
                />
            </Form.Item>
            {selectedPromotionType === PromotionTypeEnum2.Coupon ? (
                <Form.Item
                    label="Промокод"
                    name="coupon"
                    style={{ marginTop: 20 }}
                    rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                >
                    <Input
                        placeholder="Введите название промокода"
                        disabled={!isAddPersonalPromotion}
                    />
                </Form.Item>
            ) : null}
            <Form.Item
                label="Продолжительность акции"
                name="period"
                style={{ marginTop: 20 }}
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
            >
                <RangePicker
                    format={DEFAULT_DATE_FORMAT}
                    style={{ width: breakpoints.lg ? 320 : '100%' }}
                />
            </Form.Item>
            <Form.Item
                label={
                    !isAddPersonalPromotion ? (
                        <>
                            Скидка &nbsp;
                            <QuestionTooltip
                                textAlign="left"
                                title="Для применения изменения скидки загрузите новый файл с товарами."
                            />
                        </>
                    ) : (
                        'Скидка'
                    )
                }
                required
                style={{ marginTop: 20 }}
            >
                <Row
                    style={{ width: breakpoints.lg ? 320 : '100%', gap: 8 }}
                    wrap={false}
                >
                    <Form.Item
                        name="discount_value"
                        style={{ width: 110 }}
                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                    >
                        <InputNumber
                            placeholder="Число"
                            min={1}
                        />
                    </Form.Item>

                    <Form.Item
                        name="discount_type"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                    >
                        <Select
                            options={discountTypeOptions}
                            disabled={!isAddPersonalPromotion}
                        />
                    </Form.Item>
                </Row>
            </Form.Item>
        </Card>
    );
};

export default PromotionDetailsCard;
