import { useEffect, useState } from 'react';

import { useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { Button, Space, Typography, Modal, Image, Grid } from '@Shared/ui';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';
import { JoinTelegram } from '@Shared/assets/images';

const { Text, Title, Link } = Typography;
const { useBreakpoint } = Grid;

const JoinTelegramModal = () => {
    const breakpoint = useBreakpoint();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();

    useEffect(() => {
        setTimeout(() => {
            setIsModalOpen(true);
        }, 3 * 60000);
    }, [setIsModalOpen]);

    const handleCancel = () => {
        addOnboardingStatus({ status: SellerOnboardingStatuses.SHOW_JOIN_TELEGRAM_POPUP });
        setIsModalOpen(false);
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            footer={
                <Button
                    type="primary"
                    href="https://t.me/+FPLqIgu0A_ZiY2Vi"
                    target="_blank"
                    style={{ width: breakpoint.md ? 200 : '100%', marginTop: 8 }}
                >
                    Вступить в канал
                </Button>
            }
        >
            <Space
                direction="vertical"
                size={20}
                style={{ width: '100%', marginTop: 36 }}
            >
                <Image
                    src={JoinTelegram}
                    preview={false}
                    style={{ borderRadius: 12 }}
                />
                <Title
                    level={3}
                    style={{ margin: 0 }}
                >
                    💼 Присоединяйся к закрытому тг-каналу селлеров!
                </Title>
                <Text>
                    В канале{' '}
                    <Link
                        href="https://t.me/+FPLqIgu0A_ZiY2Vi"
                        target="_blank"
                    >
                        Продавцы Такпродам
                    </Link>{' '}
                    на твои вопросы отвечают не только менеджеры, а еще и команда разработки проекта.
                </Text>
                <Text>🔒 Без воды и рекламы, только то, что приносит результат.</Text>
            </Space>
        </Modal>
    );
};

export default JoinTelegramModal;
