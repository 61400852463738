import { BellOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Popover, Badge, Button } from '@Shared/ui';
import {
    useGetNotificationListQuery,
    useGetAppPrivateNotificationUnreadcountQuery,
} from '@Shared/api/rtkQueryApis/notifications';

import NotificationsPopoverContent from './NotificationsPopoverContent';

const NotificationHeaderItem = ({ isMobile = false }: { isMobile?: boolean }) => {
    const { data: notifications, isLoading } = useGetNotificationListQuery(
        { page: 1, limit: 50 },
        { pollingInterval: 60000 },
    );
    const { data: unreadData } = useGetAppPrivateNotificationUnreadcountQuery(null, { pollingInterval: 60000 });
    const {
        token: { colorText, colorWhite },
    } = theme.useToken();
    return (
        <Popover
            arrow={false}
            placement="bottomRight"
            overlayInnerStyle={{ padding: 0, borderRadius: isMobile ? '0 0 8px 8px' : '8px' }}
            content={
                <NotificationsPopoverContent
                    notifications={notifications}
                    isMobile={isMobile}
                    isLoading={isLoading}
                    unreadCount={unreadData?.unread_count || 0}
                />
            }
            overlayClassName={isMobile ? 'mobile-header-notifications-popover' : ''}
        >
            <span style={{ cursor: 'pointer' }}>
                {isMobile ? (
                    <Button
                        style={{ background: '#FFFFFF2E', border: 'none' }}
                        icon={
                            <Badge count={unreadData?.unread_count || 0}>
                                <BellOutlined style={{ color: colorWhite }} />
                            </Badge>
                        }
                    />
                ) : (
                    <Button
                        icon={
                            <Badge count={unreadData?.unread_count || 0}>
                                <BellOutlined style={{ color: colorText }} />
                            </Badge>
                        }
                    />
                )}
            </span>
        </Popover>
    );
};

export default NotificationHeaderItem;
