import { ReactNode } from 'react';

import type { FeeSetting2 } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export type CampaignType = {
    title: string;
    description: string;
    disabled?: boolean;
    icon: ReactNode;
    navigate?: string;
    hasButton?: boolean;
};

export enum ActionType {
    changeCommission = 'changeCommission',
    defaultCommission = 'defaultCommission',
    showStop = 'showStop',
    showStart = 'showStart',
}

export type ApplyActionProps = {
    producstsIds: string[];
    action: ActionType;
    commission: number;
};

export type CampaignContextType = {
    fee_setting?: FeeSetting2 | null;
    applyAction: ({ producstsIds, action, commission }: ApplyActionProps) => Promise<false | undefined>;
    totalItems?: number;
    currentPage?: number;
    setCurrentPage?: (page: number) => void;
    storeId?: string;
};

export const actionOptions = [
    { value: ActionType.changeCommission, label: 'Изменить комиссию' },
    { value: ActionType.defaultCommission, label: 'Коммисия по умолчанию' },
    { value: ActionType.showStop, label: 'Не показывать паблишерам' },
    { value: ActionType.showStart, label: 'Показывать паблишерам' },
];

export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    SEARCH_STRING = 'search_string',
    VISIBLE = 'visible',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    search_string: string;
    visible: boolean;
};

export type OptionType = { value: string; label: string };

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
