import { useNavigate } from 'react-router-dom';

import { Flex, Button } from '@Shared/ui';
import {
    PromotionDto3,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { useAppDispatch } from '@Shared/hooks';
import { showModal } from '@Entities/modals/model/modalsSlice';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

type PromotionSelectorCardProps = {
    promotion: PromotionDto3 | undefined;
};

const ActionButtons = ({ promotion }: PromotionSelectorCardProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();

    const handleApprovePromotionVisibility = async () => {
        if (promotion?.id) {
            try {
                try {
                    await setPromotionVisibility({
                        setPromotionVisibilityDto: { visible: true, promotions: [promotion?.id] },
                    });
                    dispatch(
                        showMessage({
                            componentProps: {
                                type: MessageType.Success,
                                content: `Вы начали участие в ${promotion?.title}`,
                            },
                        }),
                    );
                } catch (err) {
                    console.error(err);
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Flex
            justify="space-between"
            vertical
            gap="small"
            style={{ paddingBottom: 24 }}
        >
            {promotion?.active ? (
                <>
                    {!promotion?.visible ? (
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={handleApprovePromotionVisibility}
                            loading={isVisibilityLoading}
                        >
                            Включить
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            ghost
                            style={{ width: '100%' }}
                            onClick={() =>
                                dispatch(
                                    showModal({
                                        modalKey: ModalKeysType.declinePromotionParticipation,
                                        params: { promotionId: promotion?.id || '' },
                                    }),
                                )
                            }
                            loading={isVisibilityLoading}
                        >
                            Заявка на отключение
                        </Button>
                    )}
                </>
            ) : (
                <Button
                    danger
                    onClick={() =>
                        dispatch(
                            showModal({
                                modalKey: ModalKeysType.deletePersonalPromotion,
                                params: { promotionId: promotion?.id || '' },
                            }),
                        )
                    }
                    style={{ width: '100%' }}
                >
                    Удалить
                </Button>
            )}
            {promotion?.active ? (
                <Button
                    onClick={() => navigate(`/promotions/personal-promotions/${promotion?.id}/edit-promotion`)}
                    style={{ width: '100%' }}
                >
                    Изменить
                </Button>
            ) : null}
        </Flex>
    );
};

export default ActionButtons;
