import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        addAPersonalPromotion: build.mutation<AddAPersonalPromotionApiResponse, AddAPersonalPromotionApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/personal/',
                method: 'POST',
                body: queryArg.personalPromotionDto,
            }),
        }),
        updateThePersonalPromotion: build.mutation<
            UpdateThePersonalPromotionApiResponse,
            UpdateThePersonalPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/personal/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.personalPromotionDto2,
            }),
        }),
        deleteThePersonalPromotion: build.mutation<
            DeleteThePersonalPromotionApiResponse,
            DeleteThePersonalPromotionApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/promotion/personal/${queryArg.id}/`, method: 'DELETE' }),
        }),
        getSellerPromotionsList: build.query<GetSellerPromotionsListApiResponse, GetSellerPromotionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplace_slug: queryArg.marketplaceSlug,
                    store_id: queryArg.storeId,
                    active: queryArg.active,
                    types: queryArg.types,
                },
            }),
        }),
        viewThePromotion: build.query<ViewThePromotionApiResponse, ViewThePromotionApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/promotion/${queryArg.id}/` }),
        }),
        patchAppPrivateSellerPromotionSetpromotionvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/set-visibility/',
                method: 'PATCH',
                body: queryArg.setPromotionVisibilityDto,
            }),
        }),
        updateTheGlobalPromotion: build.mutation<UpdateTheGlobalPromotionApiResponse, UpdateTheGlobalPromotionApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/global/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.promotionDto,
            }),
        }),
        getProductsListForPromotion: build.query<
            GetProductsListForPromotionApiResponse,
            GetProductsListForPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateSellerPromotionSetpromotionproductvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setPromotionProductVisibilityDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerPromotionsApi };
export type AddAPersonalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type AddAPersonalPromotionApiArg = {
    personalPromotionDto: PersonalPromotionDto;
};
export type UpdateThePersonalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type UpdateThePersonalPromotionApiArg = {
    id: string;
    personalPromotionDto2: PersonalPromotionDto2;
};
export type DeleteThePersonalPromotionApiResponse = /** status 204 Resource removed */ void;
export type DeleteThePersonalPromotionApiArg = {
    id: string;
};
export type GetSellerPromotionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionDto3[];
};
export type GetSellerPromotionsListApiArg = {
    page?: number;
    limit?: number;
    marketplaceSlug?: MarketPlaceSlugEnum | null;
    storeId?: string | null;
    active?: boolean | null;
    types?: {
        ''?: PromotionTypeEnum3[];
    };
};
export type ViewThePromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type ViewThePromotionApiArg = {
    id: string;
};
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg = {
    setPromotionVisibilityDto: SetPromotionVisibilityDto;
};
export type UpdateTheGlobalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type UpdateTheGlobalPromotionApiArg = {
    id: string;
    promotionDto: PromotionDto;
};
export type GetProductsListForPromotionApiResponse = /** status 200 Get products list for promotion */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionProductDto2[];
};
export type GetProductsListForPromotionApiArg = {
    id: string;
    page?: number;
    limit?: number;
};
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg = {
    id: string;
    setPromotionProductVisibilityDto: SetPromotionProductVisibilityDto;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto3[];
    active: boolean;
};
export type MarketplaceStoreDto3 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type PromotionDto3 = {
    id: string;
    type: PromotionTypeEnum4;
    title: string;
    description: string | null;
    marketplace_store: MarketplaceStoreDto3;
    start_date: string;
    end_date: string;
    active?: boolean;
    visible?: boolean;
    orderAmount?: number | null;
    coupon?: string | null;
    discount_type?: DiscountTypeEnum3 | null;
    discount_value?: string | null;
};
export type Store = {
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store | null;
    marketplace?: Marketplace | null;
};
export type PersonalPromotionDto = {
    marketplace_store: MarketplaceStore2;
    type: PromotionTypeEnum2;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    coupon?: string | null;
    discount_type: DiscountTypeEnum;
    discount_value: number;
    promotion_products_file_id: string | null;
};
export type PersonalPromotionDto2 = {
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    coupon?: string | null;
    discount_type: DiscountTypeEnum2;
    discount_value: number;
    promotion_products_file_id: string | null;
};
export type SetPromotionVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotions?: string[];
};
export type PromotionDto = {
    description: string | null;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type PromotionProductDto2 = {
    id: string;
    product: ProductDto;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    visible?: boolean;
};
export type SetPromotionProductVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotion_products?: string[];
};
export enum PromotionTypeEnum4 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum DiscountTypeEnum3 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PromotionTypeEnum2 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum DiscountTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum DiscountTypeEnum2 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum MarketPlaceSlugEnum {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PromotionTypeEnum3 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export const {
    useAddAPersonalPromotionMutation,
    useUpdateThePersonalPromotionMutation,
    useDeleteThePersonalPromotionMutation,
    useGetSellerPromotionsListQuery,
    useViewThePromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
    useUpdateTheGlobalPromotionMutation,
    useGetProductsListForPromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation,
} = injectedRtkApi;
