import { PaymentTypeEnum5 } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export function getCampaignEditUrlType(campaignType: PaymentTypeEnum5 | undefined, slug?: string | null | undefined) {
    let url = '';
    if (slug) {
        switch (campaignType) {
            case PaymentTypeEnum5.Cpa:
                url = `/product-promotion/setup-campaign/cpa/${slug}`;
                break;
            case PaymentTypeEnum5.Barter:
                url = `/product-promotion/setup-campaign/barter/${slug}`;
                break;
            case PaymentTypeEnum5.Cpc:
                url = `/product-promotion/setup-campaign/cpc/${slug}`;
                break;
            case PaymentTypeEnum5.Cpp:
                url = `/product-promotion/setup-campaign/cpp/${slug}`;
                break;
        }
    }

    return url;
}
