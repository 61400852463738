import { theme } from 'antd';

import { MarketplaceStoreDropdown } from '@Features/shared';
import { Form, Card, Typography } from '@Shared/ui';
import { StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { Title, Text } = Typography;

type SelectMPstoreCardProps = {
    value: string;
    onChange?: (arg1: string, arg2: { mpStore: StoreDto3 }) => void;
    isEditForm: boolean;
};

const SelectMPstoreCard = (props: SelectMPstoreCardProps) => {
    const {
        token: { colorTextSecondary, fontSizeSM, colorTextDescription },
    } = theme.useToken();

    return (
        <Card>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Ваш магазин на маркетплейсе
            </Title>
            <Form.Item
                name="marketplace_store"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20, marginBottom: 16 }}
            >
                <MarketplaceStoreDropdown
                    {...props}
                    selectValue={props.value}
                    isDisabled={props.isEditForm}
                    setInitialValue={(options = []) => {
                        if (options?.length && !props.isEditForm) {
                            props?.onChange?.(options[0].value, options[0]);
                        }
                    }}
                />
            </Form.Item>
            <Text style={{ fontSize: fontSizeSM, color: colorTextDescription }}>
                Если в процессе настройки своей рекламной кампании вы поменяете магазин на маркетплейсе, то настройку
                нужно будет начать заново
            </Text>
        </Card>
    );
};
export default SelectMPstoreCard;
