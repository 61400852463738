// import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
// import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
// eslint-disable-next-line max-len
import {
    ProductStatisticDto2,
    SellerStatisticsByProductsApiResponse,
} from '@Shared/api/rtkQueryApis/sellerStatistics/SellerStatisticsApi';

type StatisticsTableProps = {
    data: SellerStatisticsByProductsApiResponse | undefined;
    isLoading: boolean;
    // setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text } = Typography;

const columns = [
    {
        title: 'Название магазина',
        dataIndex: 'store_title',
        key: 'store_title',
        width: 300,
    },
    {
        title: 'Маркетплейс',
        dataIndex: 'marketplace_title',
        key: 'marketplace_title',
    },
    {
        title: 'Артикул',
        dataIndex: 'product_sku',
        key: 'product_sku',
    },
    {
        title: 'Название товара',
        dataIndex: 'product_title',
        key: 'product_title',
    },
    {
        title: 'Переходы',
        dataIndex: 'clicks',
        key: 'clicks',
        width: 150,
        sorter: (a: ProductStatisticDto2, b: ProductStatisticDto2) => a.clicks - b.clicks,
    },
    {
        title: 'Конверсии',
        dataIndex: 'commissions',
        key: 'commissions',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a: ProductStatisticDto2, b: ProductStatisticDto2) => a.commissions - b.commissions,
    },
    {
        title: 'Сумма комиссий',
        dataIndex: 'amount',
        key: 'amount',
        width: 180,
        render: (amount: string) => `${amount} ₽`,
        sorter: (a: ProductStatisticDto2, b: ProductStatisticDto2) => a.amount - b.amount,
    },
];

const StatisticsTable = ({ data, isLoading }: StatisticsTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items || []}
            columns={columns}
            pagination={false}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас нет данных для отображения статистики</Text>,
                }),
            }}
        />
    );
};

export default StatisticsTable;
