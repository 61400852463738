import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, theme } from 'antd';
import Icon, { UserOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { showModal } from '@Entities/modals/model/modalsSlice';
import { useAppDispatch } from '@Shared/hooks';
import { Flex, Avatar, Badge } from '@Shared/ui';
import { pageMenuItems, userMenuItems } from '@Widgets/publisher/menuItems';
import BalancesHeaderItem from '@Widgets/publisher/BalancesHeaderItem';
import { LogoColorful } from '@Shared/assets/images';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';

import NotificationHeaderItem from '../notificationsMenu/NotificationsHeaderItem';
import RefLinkModal from '../RefLinkModal';
import './styles.scss';

const { Header } = Layout;

type MobilePageHeaderProps = {
    selectedKey: string;
};

export default function MobilePageHeader({ selectedKey: selectedPageMenuKey }: MobilePageHeaderProps) {
    const { data: user } = useGetTheUserInformationQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [openKeys, setOpenKeys] = useState([]);
    const {
        token: { colorBgContainer, colorBgSpotlight },
    } = theme.useToken();

    const isPageMenuOpened = openKeys.indexOf('page-menu') !== -1;

    const headerItems = [
        {
            key: 'page-menu',
            label: false,
            popupClassName: 'page-menu-popup',
            className: 'hide-title-content',
            id: 'joyride-products-menu-item',
            theme: 'light',
            icon: isPageMenuOpened ? <CloseOutlined /> : <MenuOutlined />,
            children: [
                {
                    label: (
                        <BalancesHeaderItem
                            align="flex-start"
                            vertical={false}
                        />
                    ),
                },
                {
                    type: 'divider',
                },
                ...pageMenuItems,
            ],
        },
        {
            className: 'logo-menu-item',
            icon: <Icon component={() => <LogoColorful />} />,
            label: false,
        },
        {
            key: 'notifications',
            label: <NotificationHeaderItem isMobile={true} />,
        },
        {
            key: 'user',
            label: false,
            children: userMenuItems(user?.onboarding_statuses || []),
            popupClassName: 'page-menu-popup',
            theme: 'light',
            className: 'hide-title-content',
            id: 'joyride-user-menu-button',
            icon: (
                <Badge
                    dot={!user?.onboarding_statuses?.includes(PublisherOnboardingStatuses.PROFILE_PAGE)}
                    offset={[-8, 8]}
                >
                    <Avatar
                        style={{ background: colorBgContainer }}
                        icon={<UserOutlined style={{ color: colorBgSpotlight }} />}
                    />
                </Badge>
            ),
        },
    ];

    const onClick = (e) => {
        const item = [...pageMenuItems, ...userMenuItems(user?.onboarding_statuses || [])].find(
            (item) => item.key === e.key,
        );
        if (item && item.href) {
            navigate(item.href);
        } else if (item && item.modal) {
            dispatch(showModal({ modalKey: item.modal }));
        }
    };

    const handleMenuOpen = (keys) => {
        setOpenKeys(keys);
    };

    return (
        <Header className="page-header__mobile">
            <RefLinkModal />
            <Flex
                justify="space-between"
                align="end"
                style={{ padding: '0 16px' }}
            >
                <Menu
                    selectedKeys={isPageMenuOpened ? [selectedPageMenuKey] : []}
                    mode="horizontal"
                    onClick={onClick}
                    onOpenChange={handleMenuOpen}
                    openKeys={openKeys}
                    items={headerItems}
                    theme="dark"
                    className="header-mobile-menu"
                />
            </Flex>
        </Header>
    );
}
