import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Button, Form, Input, Typography, Divider, Popover, Grid } from '@Shared/ui';
import { ConfirmLeavePrompt } from '@Features/shared';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { useGetIntegrationDataFromExtension } from '@Shared/hooks';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { useConnectMarketplaceToOzonMutation } from '@Shared/api/rtkQueryApis/sellerIntegrations';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import './styles.scss';

type SetupIntegrationFormProps = {
    storeId: string;
};

const { Title, Link, Text } = Typography;
const { useBreakpoint } = Grid;

const fieldsHelperText = {
    organization_id: (
        <>
            {'Находится в разделе Аналитика > Внешний трафик ('}
            <Link
                href="https://seller.ozon.ru/app/advertisement/vendor-traffic"
                target="_blank"
            >
                https://seller.ozon.ru/app/advertisement/vendor-traffic
            </Link>
            {') в личном кабинете продавца Озон. Скопируйте цифры, идущие после префикса'}
            <strong> vendor_org_</strong>
        </>
    ),
    seller_client_id: (
        <>
            {
                // eslint-disable-next-line max-len
                'Находится в разделе API Ключи в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Как подключить Seller API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/how-to-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/how-to-api
            </Link>
            {')'}
        </>
    ),
    seller_api_key: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно сгенерировать в разделе API Ключи в личном кабинете продавца Ozon. Выберите тип токена "Admin read only". Подробную инструкцию смотрите в '
            }
            <Link
                href="https://support.mitgo.com/ru/knowledge-base/article/как-настроить-интеграцию-с-ozon"
                target="_blank"
            >
                хелпцентре
            </Link>
            {'.'}
        </>
    ),
    performance_client_id: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно добавить в разделе API Ключи (вкладка Performance API) в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Начало работы с Performance API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/external-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/external-api
            </Link>
            {')'}
        </>
    ),
    performance_api_key: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно добавить в разделе API Ключи (вкладка Performance API) в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Начало работы с Performance API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/external-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/external-api
            </Link>
            {')'}
        </>
    ),
};

const OzonIntegrationForm = ({ storeId }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const { data: user } = useGetTheUserInformationQuery();
    const [addIntegration, { isLoading: isAdding, error: isAddingError }] = useConnectMarketplaceToOzonMutation();

    const {
        token: { colorTextDescription },
    } = theme.useToken();

    const handleSubmit = async (formValues) => {
        try {
            await addIntegration({
                ozonIntegrationDto: formValues,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно создана',
                    errorMessage: 'Ошибка при попытке создания интеграции',
                },
                store: storeId,
            }).unwrap();
            sendYmEvent({
                name: 'send_set_integration_success',
                o1: 'account;advertiser;store',
                o2: 'marketplaces',
                o4: 'ozon',
                advcampaignId: storeId,
                keycloakId: user?.id,
                advertiserId: user?.seller?.id,
            });

            setTimeout(() =>
                dispatch(
                    showNotification({
                        componentProps: {
                            type: NotificationType.Info,
                            message: 'У вас появилась новая рекламная кампания',
                            description:
                                // eslint-disable-next-line max-len
                                'Проверьте и отредактируйте информацию о ней. Запустите кампанию, чтобы начать быстрое продвижение товаров.',
                            btn: (
                                <Button
                                    type="link"
                                    onClick={() => navigate('/product-promotion/campaigns')}
                                >
                                    Рекламные кампании
                                </Button>
                            ),
                        },
                    }),
                    1000,
                ),
            );
            handleBack();
        } catch (err) {
            console.error(err);
        }
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        const errors = normalizeErrorsObject(isAddingError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [isAddingError, form]);

    useEffect(() => {
        if (isAdding) {
            setUnsavedChanges(false);
        }
    }, [isAdding]);

    const renderTooltip = (field) => (
        <Popover
            content={<div style={{ maxWidth: 500 }}>{fieldsHelperText[field]}</div>}
            placement={breakpoints.md ? 'right' : 'top'}
        >
            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
        </Popover>
    );

    return (
        <Form
            form={form}
            name="integration-ozon"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={() => setUnsavedChanges(true)}
            autoComplete="off"
            className="setup-integration-page__form"
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Form.Item
                label={<>ID организации {renderTooltip('organization_id')}</>}
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите ID организации" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Seller API
            </Title>
            <Form.Item
                label={<>Client ID {renderTooltip('seller_client_id')}</>}
                name="seller_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                label={<>API key {renderTooltip('seller_api_key')}</>}
                name="seller_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Perfomance API
            </Title>
            <Form.Item
                label={<>Client ID {renderTooltip('performance_client_id')}</>}
                name="performance_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                label={<>Client Secret {renderTooltip('performance_api_key')}</>}
                name="performance_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client Secret" />
            </Form.Item>
            <Divider />
            <Text type="secondary">
                Мы создадим рекламную кампанию для текущей интеграции. Вам останется только запустить ее — сделать это
                можно в разделе «Продвижение товаров».
            </Text>
            <Form.Item
                className="action-buttons-block"
                style={{ marginTop: 20 }}
            >
                <Button onClick={() => handleBack()}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAdding}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
export default OzonIntegrationForm;
