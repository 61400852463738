import { FeeTypeEnum } from '@Shared/api/full';

type formatPriceProps = {
    price: number | string;
    currency?: { shortCode?: string; symbol?: string };
    locale?: string;
    signDisplay?: 'auto' | 'always' | 'exceptZero' | 'negative' | 'never';
    trailingZeroDisplay?: 'auto' | 'stripIfInteger' | undefined;
    feeType?: FeeTypeEnum | null;
};

export const formatPrice = ({
    price = 0,
    feeType = FeeTypeEnum.Fixed,
    currency,
    locale = 'ru-RU',
    signDisplay = 'auto',
    trailingZeroDisplay = 'stripIfInteger',
}: formatPriceProps) => {
    return feeType === FeeTypeEnum.Fixed
        ? new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: currency?.shortCode || 'RUB',
              signDisplay,
              trailingZeroDisplay,
          }).format(Number(price))
        : `${price}%`;
};
