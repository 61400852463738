import { BrowserCursorClick, FileBlankCopyExchange, NotesPagesNotification } from '@Shared/assets/images/icons';

export const campaignTypes = [
    {
        title: 'Оплата за клик',
        description: 'Вы платите за переход по ссылке на ваш товар',
        icon: <BrowserCursorClick style={{ width: 36, height: 36 }} />,
        navigate: '/product-promotion/setup-campaign/cpc',
        hasButton: true,
        disabled: true,
    },
    {
        title: 'Оплата за размещение',
        description: 'Оплата за публикацию рекламного материала',
        disabled: true,
        icon: <NotesPagesNotification style={{ width: 36, height: 36 }} />,
        hasButton: true,
    },
    {
        title: 'Бартер',
        description: 'Товар продавца обменивается на услуги паблишера',
        icon: <FileBlankCopyExchange style={{ width: 36, height: 36 }} />,
        navigate: '/product-promotion/setup-campaign/barter',
        hasButton: true,
    },
];

export const cpaCampaignType = {
    title: 'Оплата за заказ',
    description: 'Вы платите только за продажи',
    icon: <BrowserCursorClick style={{ width: 36, height: 36 }} />,
};
