export const useSupportWidgetApi = (): {
    handleOpenSupportWidget: () => void;
} => {
    const handleOpenSupportWidget = () => {
        if (window?.UR) {
            window.UR.openChatWidget();
        }
    };

    return {
        handleOpenSupportWidget,
    };
};
