import { useNavigate } from 'react-router-dom';

import { Row, Space, List, Typography, Badge, Skeleton, Button } from '@Shared/ui';
import { GetNotificationListApiResponse } from '@Shared/api/rtkQueryApis/notifications/NotificationsApi';
import { usePatchAppPrivateNotificationMarkallasreadMutation } from '@Shared/api/rtkQueryApis/notifications';

import NotificationListItem from './NotificationListItem';

const { Title, Text, Link } = Typography;

const NotificationsPopoverContent = ({
    notifications,
    isMobile,
    isLoading,
    unreadCount,
}: {
    notifications: GetNotificationListApiResponse | undefined;
    isMobile?: boolean;
    isLoading?: boolean;
    unreadCount: number;
}) => {
    const navigate = useNavigate();
    const [readAllNotifications, { isLoading: isDeletingNotifications }] =
        usePatchAppPrivateNotificationMarkallasreadMutation();

    return (
        <Space
            direction="vertical"
            style={{
                width: isMobile ? '100vw' : 380,
                maxHeight: 'calc(100vh - 120px)',
                overflowY: 'auto',
                padding: 24,
                boxSizing: 'border-box',
            }}
            size={24}
        >
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Row
                        align="middle"
                        justify="space-between"
                        wrap={false}
                    >
                        <Row
                            align="middle"
                            style={{ gap: 8 }}
                            wrap={false}
                        >
                            <Title
                                level={4}
                                style={{ margin: 0 }}
                            >
                                Уведомления
                            </Title>
                            <Badge count={unreadCount} />
                        </Row>
                        <Button
                            type="text"
                            loading={isDeletingNotifications}
                            onClick={() => readAllNotifications()}
                            disabled={!unreadCount}
                        >
                            Прочитать все
                        </Button>
                    </Row>
                    <List
                        dataSource={notifications?.items || []}
                        locale={{
                            emptyText: (
                                <Text>
                                    У вас пока нет ни одного уведомления. вы можете перейти в{' '}
                                    <Link onClick={() => navigate('/profile')}>профиль</Link>
                                </Text>
                            ),
                        }}
                        renderItem={(notification) => <NotificationListItem notification={notification} />}
                    />
                </>
            )}
        </Space>
    );
};

export default NotificationsPopoverContent;
