import { ContactsApi } from './ContactsApi';

ContactsApi.enhanceEndpoints({
    addTagTypes: ['ContactsList', 'Contact'],
    endpoints: {
        getContactsList: {
            providesTags: ['ContactsList'],
        },
        viewAContact: {
            providesTags: ['Contact'],
        },
        addANewContact: {
            invalidatesTags: ['ContactsList', 'Contact'],
        },
        putAppPrivateContactUpdate: {
            invalidatesTags: ['ContactsList'],
        },
        deleteAppPrivateContactDelete: {
            invalidatesTags: ['ContactsList', 'Contact'],
        },
    },
});

export * from './ContactsApi';
