import { theme, Typography } from 'antd';
import { ReactNode } from 'react';

import { useSupportWidgetApi } from '@Shared/hooks';

const { Link } = Typography;

export const OpenSupportWidgetLink = ({ children }: { children: ReactNode }) => {
    const {
        token: { colorInfo },
    } = theme.useToken();
    const { handleOpenSupportWidget } = useSupportWidgetApi();
    return (
        <Link onClick={handleOpenSupportWidget}>
            {children}
            <div
                style={{
                    minHeight: '26px',
                    backgroundColor: colorInfo,
                    position: 'relative',
                    borderRadius: '13px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    minWidth: '26px',
                    height: '26px',
                    marginLeft: '4px',
                    display: 'inline-block',
                    top: '10px',
                }}
            >
                <span
                    className="UR_chatSocialIcon UR_iWidget"
                    style={{
                        width: '12px',
                        height: '12px',
                        display: 'inline-block',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        left: '7px',
                        top: '7px',
                        backgroundSize: 'contain',
                    }}
                />
            </div>
        </Link>
    );
};
