import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection/types';
import { useUpdateSearchParams } from '@Shared/hooks';
import { useGetProductsListForCampaignQuery } from '@Shared/api/rtkQueryApis/sellerCampaigns';

import CampaignProductsTable from './CampaignProductsTable';
import CampaignProductsFilters from './campaignProductsFilters/CampaignProductsFilters';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    search_string: searchParams.get(FilterEnum.SEARCH_STRING) || '',
    visible: Boolean(searchParams.get(FilterEnum.VISIBLE)),
});

const CampaignProductsTableBlock = ({ campaignSlug }: { campaignSlug: string }) => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data: campaignProducts, isFetching: isPromotionsLoading } = useGetProductsListForCampaignQuery(
        {
            slug: campaignSlug,
            page: filterObject.page,
            limit: filterObject.limit,
            searchString: filterObject?.search_string || '',
            visible: filterObject?.visible || undefined,
        },
        { skip: !campaignSlug },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <Space
            direction="vertical"
            size={24}
            style={{ width: '100%' }}
        >
            <CampaignProductsFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
            />
            <CampaignProductsTable
                data={campaignProducts}
                isLoading={isPromotionsLoading}
                setFilterObject={setFilterObject}
            />
        </Space>
    );
};
export default CampaignProductsTableBlock;
