import { CampaignStatusEnum } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export enum FilterEnum {
    PAYMENT_TYPES = 'paymentTypes',
}

export type FilterObjectType = {
    paymentTypes?: string[];
};

export const CampaignStatusesMap = {
    [CampaignStatusEnum.Active]: 'Активна',
    [CampaignStatusEnum.AddLegalInfo]: 'Добавьте Юр. Данные',
    [CampaignStatusEnum.Disabled]: 'Отключена',
    [CampaignStatusEnum.Pending]: 'Ожидает запуска',
    [CampaignStatusEnum.TopUpBalance]: 'Пополните баланс',
    [CampaignStatusEnum.Verifying]: 'На проверке',
    [CampaignStatusEnum.Disconnected]: 'Приостановлена',
};

export type OptionType = { value: string; label: string };
