import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useGetMarketplaceStoreListWithYmlExportLinksQuery } from '@Shared/api/full';
import { Select, Spin } from '@Shared/ui';

type SearchProductDropdownProps = {
    sourceId: string;
    value?: number;
    onChange?: (arg1: number) => void;
    isDisabled?: boolean;
};

const SourceMPstoresSearchDropdown = ({
    sourceId,
    value,
    onChange,
    isDisabled = false,
}: SearchProductDropdownProps) => {
    const [searchValue, setSearchValue] = useState('');
    const { data: marketplace_stores, isFetching } = useGetMarketplaceStoreListWithYmlExportLinksQuery(
        {
            page: 1,
            limit: 50,
            title: searchValue,
            source: sourceId,
        },
        { skip: !sourceId },
    );

    const handleSearch = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 500);

    const options = marketplace_stores?.items?.map((marketplace_store) => ({
        label: marketplace_store?.title || '',
        value: marketplace_store.id,
    }));

    return (
        <Select
            value={value}
            onChange={onChange}
            showSearch
            disabled={isDisabled}
            placeholder="Начните вводить название магазина на маркетплейсе"
            onSearch={handleSearch}
            loading={isFetching}
            allowClear
            notFoundContent={isFetching ? <Spin size="small" /> : 'Не найдено'}
            filterOption={false}
            options={isFetching ? [] : options}
        />
    );
};

export default SourceMPstoresSearchDropdown;
