import { theme } from 'antd';

import { Card, Space, Row, Typography, Button } from '@Shared/ui';

const { Title, Text } = Typography;

const AccountActionsCard = () => {
    const {
        token: { fontSizeLG, colorTextTertiary },
    } = theme.useToken();

    return (
        <Card>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Row>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Действия с аккаунтом
                    </Title>
                </Row>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    <Text style={{ color: colorTextTertiary, fontSize: fontSizeLG }}>Удаление аккаунта</Text>
                    <Button
                        type="link"
                        disabled
                    >
                        Удалить
                    </Button>
                </Row>
            </Space>
        </Card>
    );
};

export default AccountActionsCard;
