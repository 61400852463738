import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { CPCformCard } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/cpc';

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/product-promotion',
        title: 'Продвижение товаров',
    },
    {
        path: 'campaigns',
        title: 'Рекламные кампании',
    },
    {
        path: '/product-promotion/setup-campaign/cpc',
        title: 'Настройка рекламной кампании',
    },
];

const SetupCPCcampaignPage = () => {
    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />
            <CPCformCard />
        </PageContent>
    );
};
export default SetupCPCcampaignPage;
