import { Drawer } from '@Shared/ui';
import { useGetProductsDetailsWithLinksQuery, useViewASourceQuery } from '@Shared/api/rtkQueryApis/publisherSources';

import { ProductDetails } from './ProductDetails';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    productId: string;
    sourceId: string;
};

export const ProductCatalogDrawer = ({ isDrawerOpen, setDrawerOpen, productId, sourceId }: ProductDrawerProps) => {
    const { data: productCampaign, isFetching } = useGetProductsDetailsWithLinksQuery(
        {
            source: sourceId,
            productCampaign: productId,
        },
        { skip: !productId },
    );
    const { data: source } = useViewASourceQuery({ source: sourceId }, { skip: !sourceId });

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Детали продукта"
        >
            {!!productCampaign && (
                <ProductDetails
                    productCampaign={productCampaign}
                    source={source}
                />
            )}
        </Drawer>
    );
};
