import { FC } from 'react';
import { Provider } from 'react-redux';
import { YMInitializer } from 'react-yandex-metrika';

import { ymBusinessAccounts, ymBusinessOptions, ymMarketingAccounts, ymOptions } from '@Shared/constants/analytics';

import TopLevelWrapper from './TopLevelWrapper';

import { store } from '../providers/redux/store';
import Theme from '../providers/AppTheme';
import './../providers/i18n/i18n';

const App: FC = () => {
    return (
        <Theme>
            <Provider store={store}>
                <TopLevelWrapper />

                {!!ymBusinessAccounts.length && (
                    <YMInitializer
                        accounts={ymBusinessAccounts}
                        options={ymBusinessOptions}
                        version="2"
                    />
                )}
                {!!ymMarketingAccounts.length && (
                    <YMInitializer
                        accounts={ymMarketingAccounts}
                        options={ymOptions}
                        version="2"
                    />
                )}
            </Provider>
        </Theme>
    );
};

export default App;
