import { useEffect, ReactNode } from 'react';

import { Form, Typography, Input, Button } from '@Shared/ui';
import { useGetIntegrationDataFromExtension } from '@Shared/hooks';
import {
    useGetAppPrivateSellerIntegrationOzonViewQuery,
    usePutAppPrivateSellerIntegrationOzonUpdateMutation,
} from '@Shared/api/rtkQueryApis/sellerIntegrations';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

const { Title } = Typography;

type StoreDetailsDrawerProps = {
    setOpen: (arg1: boolean) => void;
    storeId: string;
    setDrawerExtraContent: (arg1: ReactNode) => void;
};

const OzonSetupIntegrationForm = ({ setOpen, storeId, setDrawerExtraContent }: StoreDetailsDrawerProps) => {
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const [updateIntegration, { isLoading: isUpdating, error: isUpdatingError }] =
        usePutAppPrivateSellerIntegrationOzonUpdateMutation();
    const { data: integrationData } = useGetAppPrivateSellerIntegrationOzonViewQuery(
        {
            store: storeId,
        },
        { skip: !storeId },
    );

    useEffect(() => {
        setDrawerExtraContent(
            <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isUpdating}
            >
                Сохранить
            </Button>,
        );
    }, [form, isUpdating, setDrawerExtraContent]);

    useEffect(() => {
        const errors = normalizeErrorsObject(isUpdatingError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [isUpdatingError, form]);

    const handleSubmit = async (formValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { organization_id, ...restFormData } = formValues;
        try {
            await updateIntegration({
                ozonIntegrationDto2: restFormData,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно обновлена',
                    errorMessage: 'Ошибка при попытке обновлении интеграции',
                },
                store: storeId,
            }).unwrap();
            setOpen(false);
            form.resetFields();
        } catch (err) {
            console.error(err);
        }
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        if (integrationData) {
            form.setFieldsValue(integrationData);
        }
    }, [form, integrationData]);

    return (
        <Form
            form={form}
            name="integration-ozon"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
        >
            <Title
                style={{ marginBottom: 8 }}
                level={4}
            >
                Настройка интеграции
            </Title>
            <Form.Item
                label="ID организации"
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input
                    placeholder="Укажите ID организации"
                    disabled
                />
            </Form.Item>
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Seller API
            </Title>
            <Form.Item
                label="Client ID"
                name="seller_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                label="API key"
                name="seller_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Perfomance API
            </Title>
            <Form.Item
                label="Client ID"
                name="performance_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                label="Client Secret"
                name="performance_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите Client Secret" />
            </Form.Item>
        </Form>
    );
};

export default OzonSetupIntegrationForm;
