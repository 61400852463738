import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Button } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';

const { Title, Text } = Typography;

const DeleteProductCampaignModal = () => {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector(selectModals);
    const remove = modalState?.params?.remove;
    const fieldName = modalState?.params?.fieldName;
    const isOpen = modalState.modalKey === ModalKeysType.deleteCPCproductCampaign && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Вы уверены что хотите удалить товар?
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={
                <>
                    <Button
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Отменить
                    </Button>{' '}
                    <Button
                        danger
                        type="primary"
                        onClick={() => {
                            remove(fieldName);
                            handleCancel();
                        }}
                    >
                        Да, удалить
                    </Button>
                </>
            }
        >
            <Text>
                После удаления товара вы не сможете его восстановить. <br /> Изменения применятся только после
                сохранения
            </Text>
        </Modal>
    );
};
export default DeleteProductCampaignModal;
