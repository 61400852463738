import { Space } from '@Shared/ui';

import UserAuthInfoCard from './UserAuthInfoCard';
import ContactsInfoCard from './ContactsInfoCard';
import AccountActionsCard from './AccountActionsCard';
import { AddEditContactModal } from './modals';

const ProfileSettingsBlock = () => {
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <UserAuthInfoCard />
            <ContactsInfoCard />
            <AccountActionsCard />
            <AddEditContactModal />
        </Space>
    );
};

export default ProfileSettingsBlock;
