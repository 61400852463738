import { MouseEvent, ReactNode, useState } from 'react';
import Icon, { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';

import { MPAvatarWithBadge } from '@Features/seller/stores/MPAvatar';
import { StoreDto3, MarketplaceStoreDto2 } from '@Shared/api/rtkQueryApis/sellerStores';
import { Avatar, Badge, Button, Table } from '@Shared/ui';
import { DrawerKeys, ModalKeys } from '@Shared/model/seller/stores/storesPageTypes';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import ActionsMenu from './ActionsMenu';
import StoreDrawer from './StoreDrawer';
import StoreModal from './StoreModal';

const { Column } = Table;

type StoresTableProps = { data: StoreDto3[]; isLoading: boolean; EmptyTableView: ReactNode };

const StoresTable = ({ data, isLoading, EmptyTableView }: StoresTableProps) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [drawerContentType, setDrawerContentType] = useState<DrawerKeys>('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState<ModalKeys>('');
    const [currentStoreId, setCurrentStoreId] = useState('');

    const { data: user } = useGetTheUserInformationQuery();

    const handleClickSetIntegration = (e: MouseEvent, id: string) => {
        e.stopPropagation();
        setCurrentStoreId(id);
        setModalOpen(true);
        setModalType('newIntegration');
        sendYmEvent({
            name: 'click_set_integration',
            o1: 'account;advertiser;store',
            o2: 'store',
            advcampaignId: currentStoreId,
            advertiserId: user?.seller?.id,
            keycloakId: user?.id,
        });
    };

    return (
        <>
            <Table
                dataSource={data}
                loading={isLoading}
                style={{ marginTop: 24 }}
                rowKey="id"
                scroll={{ x: '1000px' }}
                pagination={false}
                className="stores-table"
                locale={{
                    emptyText: EmptyTableView,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setCurrentStoreId(record.id);
                            setDrawerOpen(true);
                            setDrawerContentType('storeDetails');
                        },
                    };
                }}
            >
                <Column
                    title="Название магазина"
                    dataIndex="title"
                    key="title"
                    ellipsis
                    render={(title: string, record: StoreDto3) => (
                        <>
                            <Avatar
                                size={40}
                                shape="square"
                                icon={
                                    record.logo ? (
                                        <Icon
                                            style={{ width: 38, height: 38 }}
                                            component={() => (
                                                <img
                                                    style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                                                    src={record.logo}
                                                    alt="store-logo"
                                                />
                                            )}
                                        />
                                    ) : (
                                        <UserOutlined />
                                    )
                                }
                            />
                            <span style={{ marginLeft: 16 }}>{title}</span>
                        </>
                    )}
                />
                <Column
                    title="Статус"
                    dataIndex="active"
                    key="active"
                    render={(active: boolean) =>
                        active ? (
                            <>
                                <Badge status="success" /> &nbsp;Активный
                            </>
                        ) : (
                            <>
                                <Badge status="error" /> &nbsp;Не активный
                            </>
                        )
                    }
                />
                <Column
                    title="Маркетплейсы"
                    dataIndex="marketplaceStores"
                    key="marketplaceStores"
                    render={(marketplaceStores: MarketplaceStoreDto2[], record: StoreDto3) => {
                        return marketplaceStores?.length ? (
                            <Avatar.Group max={{ count: 3, style: { color: '#FA8C16', backgroundColor: '#FFF7E6' } }}>
                                {marketplaceStores.map((marketplace) => (
                                    <MPAvatarWithBadge
                                        key={marketplace.marketplace_slug}
                                        slug={marketplace.marketplace_slug}
                                        status={marketplace.status}
                                    />
                                ))}
                            </Avatar.Group>
                        ) : (
                            <Button
                                icon={<PlusCircleOutlined />}
                                iconPosition="start"
                                type="link"
                                id="joyride-setup-integration-button"
                                onClick={(e) => handleClickSetIntegration(e, record.id)}
                            >
                                Настроить интеграцию
                            </Button>
                        );
                    }}
                />
                <Column
                    align="right"
                    title=""
                    key="actions"
                    render={(_, record) => (
                        <ActionsMenu
                            setDrawerOpen={setDrawerOpen}
                            setDrawerContentType={setDrawerContentType}
                            setModalType={setModalType}
                            setModalOpen={setModalOpen}
                            currentStoreId={record.id}
                            setCurrentStoreId={setCurrentStoreId}
                        />
                    )}
                />
            </Table>
            <StoreDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                contentType={drawerContentType}
                setDrawerContentType={setDrawerContentType}
                storeId={currentStoreId}
                setModalOpen={setModalOpen}
                setModalType={setModalType}
            />
            <StoreModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modalType={modalType}
                storeId={currentStoreId}
                setDrawerOpen={setDrawerOpen}
            />
        </>
    );
};

export default StoresTable;
