import { Dispatch, SetStateAction, Key, useCallback, useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, Flex, Button, Space, Row, Select, Grid, QuestionTooltip } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
import {
    GetProductsListForPromotionApiResponse,
    PromotionProductDto2,
    usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { ProductNameCell } from '@Features/shared/TableCells';

import { PriceDiscountCell } from './cells';
import { ActionType, actionOptions } from './types';

type PromotionProductsTableProps = {
    data: GetProductsListForPromotionApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    promotionId: string;
};

const { Text, Link } = Typography;
const { useBreakpoint } = Grid;

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (_: unknown, { product }: PromotionProductDto2) => (
            <ProductNameCell
                product={product}
                showCategory
            />
        ),
    },
    {
        title: (
            <>
                Стоимость &nbsp;
                <QuestionTooltip
                    title="Цена товара на маркетплейсе может отличаться. 
                    Вы получите комиссию от той цены, по которой товар будет куплен."
                />
            </>
        ),
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, promotionProduct: PromotionProductDto2) => (
            <PriceDiscountCell promotionProduct={promotionProduct} />
        ),
    },
    {
        title: 'Скидка',
        dataIndex: 'price_percent',
        key: 'price_percent',
        render: (price_percent: Pick<PromotionProductDto2, 'price_percent'>) => `${price_percent || 0}%`,
    },
    {
        title: 'Ссылка на товар',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (_: unknown, { product }: PromotionProductDto2) => (
            <Link
                target="_blank"
                href={product.external_link}
            >
                {product.external_link}
            </Link>
        ),
    },
];

const PersonalPromotionProductsTable = ({
    data,
    isLoading,
    setFilterObject,
    promotionId,
}: PromotionProductsTableProps) => {
    const breakpoints = useBreakpoint();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedAction, setAction] = useState<string>(ActionType.showStart);
    const [updateProductsvisibility] = usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation();

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleChangeProductsVisibility = useCallback(
        async ({ productIds, action }: { productIds: string[]; action: ActionType }) => {
            try {
                await updateProductsvisibility({
                    id: promotionId,
                    setPromotionProductVisibilityDto: {
                        visible: action === ActionType.showStart,
                        promotion_products: productIds,
                    },
                }).unwrap();
                setSelectedRowKeys([]);
            } catch (err) {
                console.error(err);
            }
        },
        [promotionId, updateProductsvisibility],
    );

    const visibilityColumn = {
        title: 'Показ',
        dataIndex: 'showCell',
        key: 'showCell',
        width: 140,
        render: (text: unknown, { id, visible }: PromotionProductDto2) => (
            <Button
                type="link"
                style={{ color: visible ? 'var(--ant-color-link)' : 'var(--ant-color-text-description)' }}
                icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={() => {
                    handleChangeProductsVisibility({
                        productIds: [id],
                        action: visible ? ActionType.showStop : ActionType.showStart,
                    });
                }}
            />
        ),
    };

    const tableFooter = useCallback(() => {
        return (
            <Flex
                style={{ flexDirection: breakpoints.lg ? 'row' : 'column' }}
                justify="space-between"
            >
                <Space>
                    {selectedRowKeys.length > 0 ? (
                        <>
                            <Text type="secondary">Выбрано {selectedRowKeys.length}</Text>
                            <Button
                                type="link"
                                onClick={() => setSelectedRowKeys([])}
                            >
                                Отменить выбор
                            </Button>
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Space>

                <Row
                    style={{ gap: 12, width: breakpoints.lg ? 'auto' : '100%' }}
                    wrap={breakpoints.md ? false : true}
                >
                    <Select
                        options={actionOptions}
                        onChange={setAction}
                        value={selectedAction}
                        style={{ flex: breakpoints.lg ? 'unset' : 'auto', width: breakpoints.md ? '225px' : '100%' }}
                    />
                    <Button
                        type="primary"
                        ghost
                        onClick={() =>
                            handleChangeProductsVisibility({
                                productIds: selectedRowKeys as string[],
                                action: selectedAction as ActionType,
                            })
                        }
                        style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    >
                        Подтвердить
                    </Button>
                </Row>
            </Flex>
        );
    }, [breakpoints.lg, breakpoints.md, handleChangeProductsVisibility, selectedAction, selectedRowKeys]);

    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            columns={[...columns, visibilityColumn]}
            rowKey="id"
            rowSelection={rowSelection}
            footer={tableFooter}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>Нет продуктов для выбора</Text>,
                }),
            }}
        />
    );
};

export default PersonalPromotionProductsTable;
