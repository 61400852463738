import { useEffect } from 'react';

import { ProfileSettingsBlock, ProfileInfoBlock } from '@Features/publisher/profile';
import { Row, Grid } from '@Shared/ui';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';

const { useBreakpoint } = Grid;

const ProfileTab = () => {
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();
    const { data: user, isSuccess } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    useEffect(() => {
        if (isSuccess && !user?.onboarding_statuses?.includes(PublisherOnboardingStatuses.PROFILE_PAGE)) {
            addOnboardingStatus({ status: PublisherOnboardingStatuses.PROFILE_PAGE });
        }
    }, [addOnboardingStatus, isSuccess, user]);

    return (
        <Row
            wrap={false}
            style={{
                width: '100%',
                gap: 24,
                flexDirection: breakpoints.lg ? 'row' : 'column',
                alignItems: 'flex-start',
            }}
        >
            <ProfileSettingsBlock />
            <ProfileInfoBlock />
        </Row>
    );
};
export default ProfileTab;
