import { Step } from 'react-joyride';

export enum PublisherOnboardingStatuses {
    SOURCE_CREATION = 'source_creation',
    SOURCE_VERIFICATION = 'source_verification',
    LEGAL_INFO = 'legal_info',
    GETTING_STARTED = 'getting_started',
    PROFILE_PAGE = 'profile_page',
}

export enum SellerOnboardingStatuses {
    STORE_CREATION = 'store_creation',
    MARKETPLACE_STORE_INTEGRATION = 'marketplace_store_integration',
    LEGAL_INFO = 'legal_info',
    TOP_UP_BALANCE = 'top_up_balance',
    CAMPAIGN_LAUNCH = 'campaign_launch',
    SHOW_JOIN_TELEGRAM_POPUP = 'show_join_telegram_popup',
    PROFILE_PAGE = 'profile_page',
}

export type JoyrideStepType<T> = Step & {
    stepStatus: T;
    stepStatusOrder?: number;
    pauseAfterNext?: boolean;
    postStatusToServer?: boolean;
};
