import { useEffect } from 'react';

import { Row, Col, Grid } from '@Shared/ui';
import { useUpdateSearchParams } from '@Shared/hooks';
import { FilterObjectType } from '@Features/publisher/products/cpa/model/types';
import { SearchFilter, MarketplaceFilter, ProductCategoriesFilter, CheckboxFilter } from '@Features/shared/filters';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import ExportButton from './ExportButton';
// import StoreFilter from './StoreFilter';

const { useBreakpoint } = Grid;

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    sourceId: string;
};

const ProductCatalogFilters = (props: ProductsFilterProps) => {
    const updateSearchParams = useUpdateSearchParams();
    const breakpoints = useBreakpoint();

    const { data: user } = useGetTheUserInformationQuery();

    useEffect(() => {
        updateSearchParams(props.filterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterObject]);

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col id="joyride-product-filters">
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    {/* <StoreFilter {...props} /> */}
                    <MarketplaceFilter<FilterObjectType>
                        selectProps={{ mode: 'multiple' }}
                        propName="marketplaces"
                        {...props}
                        onChange={(value) => {
                            value.forEach((marketplace) => {
                                sendYmEvent({
                                    name: 'click_catalog_filter',
                                    o1: 'account;publisher;catalog',
                                    o2: 'filter;change marketplace',
                                    o4: marketplace,
                                    publisherId: user?.publisher?.id,
                                    keycloakId: user?.id,
                                });
                            });
                        }}
                    />
                    <ProductCategoriesFilter
                        {...props}
                        onChange={(value) => {
                            value.forEach((category) => {
                                sendYmEvent({
                                    name: 'click_catalog_filter',
                                    o1: 'account;publisher;catalog',
                                    o2: 'filter;change category',
                                    o4: category,
                                    publisherId: user?.publisher?.id,
                                    keycloakId: user?.id,
                                });
                            });
                        }}
                    />
                    <SearchFilter<FilterObjectType>
                        {...props}
                        propName="search_string"
                        inputProps={{ placeholder: 'Артикул или наименование' }}
                    />
                    {breakpoints.md ? (
                        <CheckboxFilter<FilterObjectType>
                            label="Только избранные"
                            propName="favorite"
                            {...props}
                            onChange={(value) => {
                                sendYmEvent({
                                    name: 'click_catalog_filter',
                                    o1: 'account;publisher;catalog',
                                    o2: 'filter;change wishlist',
                                    o4: value ? 'enable' : 'disable',
                                    publisherId: user?.publisher?.id,
                                    keycloakId: user?.id,
                                });
                            }}
                        />
                    ) : null}
                </Row>
            </Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    {breakpoints.md ? null : (
                        <CheckboxFilter<FilterObjectType>
                            label="Только избранные"
                            propName="favorite"
                            {...props}
                        />
                    )}
                    <ExportButton sourceId={props.sourceId} />
                </Row>
            </Col>
        </Row>
    );
};

export default ProductCatalogFilters;
