import { Row, Col, Flex, Typography } from '@Shared/ui';
import { MarketplaceStoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores';
import { marketplaceStoreStatuses } from '@Features/seller/stores/storesTable/drawerContent/marketplaceStoreStatus';
import { MPAvatarWithBadge } from '@Features/seller/stores/MPAvatar';

const { Text } = Typography;

type MarketplaceBlockProps = {
    selectedMarketplace: MarketplaceStoreDto3 | null;
};

const MarketplaceBlock = ({ selectedMarketplace }: MarketplaceBlockProps) => {
    return (
        <Row justify="space-between">
            <Flex
                align="center"
                gap={12}
            >
                <MPAvatarWithBadge
                    key={selectedMarketplace?.marketplace_slug || ''}
                    slug={selectedMarketplace?.marketplace_slug || ''}
                    status={selectedMarketplace?.status || ''}
                />
                <Text>{selectedMarketplace?.marketplace_title || ''}</Text>
            </Flex>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
                {marketplaceStoreStatuses[selectedMarketplace?.status || '']}
            </Col>
        </Row>
    );
};

export default MarketplaceBlock;
