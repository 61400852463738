import { theme } from 'antd';

import { MarketplaceStoreDropdown } from '@Features/shared';
import { Form, Card, Typography } from '@Shared/ui';
import { StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { Title } = Typography;

type SelectMPstoreCardProps = {
    value: string;
    onChange?: (arg1: string, arg2: { mpStore: StoreDto3 }) => void;
    isFormDisabled: boolean;
    isEditForm: boolean;
};

const SelectMPstoreCard = (props: SelectMPstoreCardProps) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    return (
        <Card>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Ваш магазин на маркетплейсе
            </Title>
            <Form.Item
                name={['marketplace_store']}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <MarketplaceStoreDropdown
                    {...props}
                    selectValue={props.value}
                    isDisabled={props.isFormDisabled || props.isEditForm}
                />
            </Form.Item>
        </Card>
    );
};
export default SelectMPstoreCard;
