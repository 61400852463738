/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const WIDGET_ID = 303;

declare global {
    interface Window {
        UR: {
            userConfig: (user: { userEmail: string; token: string; userName?: string }) => void;
            ns: (property: string, widgetId: number) => any;
            openChatWidget: () => void;
        };
    }
}

// TODO: move methods of widget to useSupportWidgetApi
export const useAuthSupportWidget = () => {
    const { data: user, isSuccess } = useGetTheUserInformationQuery();

    useEffect(() => {
        if (user && user.email && isSuccess && window?.UR) {
            const getUserName = (): string => {
                if (user?.firstName && user?.lastName) {
                    return `${user.firstName} ${user.lastName}`;
                }

                if (user?.firstName || user?.lastName) {
                    return (user.firstName || user.lastName) as string;
                }

                return 'Guest';
            };

            window.UR.userConfig({
                userEmail: user.email,
                token: 'takprodam', // required for lib, but no need in our case
                userName: getUserName(),
            });
            const database = window.UR.ns('chat', WIDGET_ID).get('database');
            if (database) {
                const conversations = JSON.parse(database.localStorage().getItem('urchat_conversations'));

                if (conversations) {
                    database.localStorage().setItem(
                        'urchat_conversations',
                        JSON.stringify(
                            Object.keys(conversations)
                                .filter((key) => key !== '-2')
                                .reduce<Record<string, any>>((obj, key) => {
                                    obj[key] = conversations[key];
                                    return obj;
                                }, {}),
                        ),
                    );
                }

                database.localStorage().removeItem('urchat_scenario');
            }
            const chatReload = window.UR.ns('chat', WIDGET_ID).get('chatReload');
            if (chatReload) {
                chatReload();
            }
        }
    }, [user, isSuccess]);
};
