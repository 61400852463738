export enum ModalKeysType {
    declineCommission = 'decline-commission',
    refLink = 'ref-link',
    approveCommission = 'approve-commission',
    approvePromotionParticipation = 'approve-promotion-participation',
    declinePromotionParticipation = 'decline-promotion-participation',
    deletePersonalPromotion = 'decline-personal-promotion',
    addEditContact = 'add-edit-contact',
    exportPublisherProducts = 'export-publisher-products',
    deleteCPCproductCampaign = 'delete-cpc-product-campaign',
}
