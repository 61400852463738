import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Card, Space, Row, Typography, Button, Grid } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { PenEdit } from '@Shared/assets/images/icons';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const UserAuthInfoCard = () => {
    const breakpoints = useBreakpoint();
    const { data: user } = useGetTheUserInformationQuery();
    const {
        token: { fontSizeLG, colorTextTertiary },
    } = theme.useToken();

    return (
        <Card>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={24}
            >
                <Row style={{ flexDirection: 'column', gap: 4 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Настройки Mitgo ID
                    </Title>
                    <Text style={{ color: colorTextTertiary }}>
                        Вы будете перенаправлены на страницу My Passport ID
                    </Text>
                </Row>
                <Row style={{ flexDirection: 'column' }}>
                    <Row
                        justify="space-between"
                        align="middle"
                        wrap={false}
                        style={{ gap: 10 }}
                    >
                        <Text style={{ color: colorTextTertiary, fontSize: fontSizeLG, minWidth: 42 }}>Email</Text>
                        {breakpoints.md ? (
                            <Text
                                type="secondary"
                                style={{ fontSize: fontSizeLG }}
                            >
                                {user?.email || '-'}
                            </Text>
                        ) : null}
                        <Button
                            type="link"
                            disabled
                            icon={breakpoints.md ? null : <Icon component={() => <PenEdit />} />}
                        >
                            {breakpoints.md ? 'Редактировать' : ''}
                        </Button>
                    </Row>
                    {!breakpoints.md ? (
                        <Row>
                            <Text
                                type="secondary"
                                style={{ fontSize: fontSizeLG }}
                            >
                                {user?.email || '-'}
                            </Text>
                        </Row>
                    ) : null}
                </Row>
            </Space>
        </Card>
    );
};

export default UserAuthInfoCard;
