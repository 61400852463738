import { NotificationsApi } from './NotificationsApi';

NotificationsApi.enhanceEndpoints({
    addTagTypes: ['NotificationsList', 'UnreadData'],
    endpoints: {
        getNotificationList: {
            providesTags: ['NotificationsList'],
        },
        getAppPrivateNotificationUnreadcount: {
            providesTags: ['UnreadData'],
        },
        patchAppPrivateNotificationMarkasread: {
            invalidatesTags: ['NotificationsList', 'UnreadData'],
        },
        deleteAppPrivateNotificationDelete: {
            invalidatesTags: ['NotificationsList', 'UnreadData'],
        },
        patchAppPrivateNotificationMarkallasread: {
            invalidatesTags: ['NotificationsList', 'UnreadData'],
        },
    },
});

export * from './NotificationsApi';
