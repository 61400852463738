import { ReactNode } from 'react';

import { MarketplaceStoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores';
import { DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';
import { Col } from '@Shared/ui';

import MarketplaceBlock from './MarketplaceBlock';
import { OzonIntegrationForm, WildberriesIntegrationForm } from './marketplaceForms';

type StoreDetailsFormProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    storeId: string;
    selectedMarketplace: MarketplaceStoreDto3 | null;
    setDrawerExtraContent: (arg1: ReactNode) => void;
};

const SetupIntegrationForm = ({
    setOpen,
    storeId,
    selectedMarketplace,
    setDrawerExtraContent,
}: StoreDetailsFormProps) => {
    const renderIntegrationForm = {
        ozon: (
            <OzonIntegrationForm
                setOpen={setOpen}
                storeId={storeId}
                setDrawerExtraContent={setDrawerExtraContent}
            />
        ),
        wildberries: (
            <WildberriesIntegrationForm
                setOpen={setOpen}
                storeId={storeId}
                setDrawerExtraContent={setDrawerExtraContent}
            />
        ),
    };

    return (
        <Col>
            <MarketplaceBlock selectedMarketplace={selectedMarketplace} />
            {renderIntegrationForm[selectedMarketplace?.marketplace_slug]}
        </Col>
    );
};

export default SetupIntegrationForm;
