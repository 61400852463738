import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import notificationsReducer from '@Entities/notifications/model/notificationSlice';
import modalsReducer from '@Entities/modals/model/modalsSlice';
import messagesReducer from '@Entities/messages/model/messagesSlice';
import joyrideReducer from '@Entities/joyride/model/joyrideSlice';
import { fullApi } from '@Shared/api/full';

import { errorMiddleware } from './middleware';

const rootReducer = combineReducers({
    notifications: notificationsReducer,
    modals: modalsReducer,
    messages: messagesReducer,
    joyride: joyrideReducer,
    [fullApi.reducerPath]: fullApi.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(fullApi.middleware, errorMiddleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
