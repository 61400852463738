import { Dispatch, SetStateAction } from 'react';
import { EditOutlined, CloseCircleOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

import { showModal } from '@Entities/modals/model/modalsSlice';
import { useAppDispatch } from '@Shared/hooks';
import { Button } from '@Shared/ui';
import { PromotionDto3 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { ModalKeysType } from '@Shared/model/modalTypes';

import type { MenuProps } from 'antd';

type ActionsMenuProps = {
    promotion: PromotionDto3;
    setPromotionId: Dispatch<SetStateAction<string>>;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const ActionsMenu = ({ promotion, setPromotionId, setDrawerOpen }: ActionsMenuProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const items: MenuProps['items'] = [
        {
            key: 'Посмотреть',
            label: 'Посмотреть',
            icon: <EyeOutlined />,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                setPromotionId(promotion.id);
                setDrawerOpen(true);
            },
        },
        {
            key: 'Изменить',
            label: 'Изменить',
            disabled: !promotion.active,
            icon: <EditOutlined />,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                navigate(`/promotions/personal-promotions/${promotion.id}/edit-promotion`);
            },
        },
        {
            type: 'divider',
        },

        {
            key: 'Удалить',
            label: 'Удалить',
            icon: <CloseCircleOutlined />,
            disabled: promotion.active,
            danger: true,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                dispatch(
                    showModal({
                        modalKey: ModalKeysType.deletePersonalPromotion,
                        params: { promotionId: promotion.id },
                    }),
                );
            },
        },
    ];

    return (
        <Dropdown
            menu={{ items }}
            placement="bottomRight"
        >
            <Button
                type="text"
                onClick={(e) => e.stopPropagation()}
            >
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
};

export default ActionsMenu;
