import { type CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Checkbox } from '@Shared/ui';

type CheckboxFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    checkboxProps?: CheckboxProps;
    label?: string;
    onChange?: (value: boolean) => void;
};

const CheckboxFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'active' as keyof FilterObjectType,
    checkboxProps = {},
    label = '',
    onChange,
}: CheckboxFilterProps<FilterObjectType>) => {
    const handleOnChange = (e: CheckboxChangeEvent) => {
        setFilterObject({ ...filterObject, [propName]: e.target.checked, page: 1 });
        onChange?.(e.target.checked);
    };

    return (
        <Checkbox
            checked={filterObject[propName] || false}
            onChange={handleOnChange}
            {...checkboxProps}
        >
            {label}
        </Checkbox>
    );
};

export default CheckboxFilter;
