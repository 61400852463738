import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Card, Space, Row, Typography, Button, Skeleton, Grid } from '@Shared/ui';
import {
    useGetContactsListQuery,
    ContactTypeEnum,
    useDeleteAppPrivateContactDeleteMutation,
} from '@Shared/api/rtkQueryApis/contacts';
import { ContactIcons, PlusAdd, PenEdit, TrashDeleteBin } from '@Shared/assets/images/icons';
import { useAppDispatch } from '@Shared/hooks';
import { showModal } from '@Entities/modals/model/modalsSlice';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

import { contactTypesMap } from './model/types';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const contactsIconsMap = ({ iconHeight }: { iconHeight: number }) => ({
    [ContactTypeEnum.Telegram]: <ContactIcons.Telegram style={{ height: iconHeight }} />,
    [ContactTypeEnum.Whatsapp]: <ContactIcons.WhatsApp style={{ height: iconHeight }} />,
    [ContactTypeEnum.Phone]: <ContactIcons.ContactPhone style={{ height: iconHeight }} />,
    [ContactTypeEnum.Email]: <ContactIcons.ContactEmail style={{ height: iconHeight }} />,
});

const ContactsInfoCard = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const { data: contactList, isLoading } = useGetContactsListQuery();
    const [deleteContact] = useDeleteAppPrivateContactDeleteMutation();
    const {
        token: { fontSizeLG, colorTextTertiary, colorTextQuaternary, fontSizeSM, colorBorderSecondary },
    } = theme.useToken();

    const handleDeleteContact = async (contactId: string) => {
        try {
            await deleteContact({ contact: contactId }).unwrap();
            dispatch(
                showMessage({
                    componentProps: {
                        type: MessageType.Success,
                        content: 'Контакт успешно удален',
                    },
                }),
            );
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Card>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Space
                    direction="vertical"
                    style={{ width: '100%' }}
                    size={24}
                >
                    <Row style={{ flexDirection: 'column', gap: 4 }}>
                        <Title
                            level={5}
                            style={{ margin: 0 }}
                        >
                            Контактные данные
                        </Title>
                        <Text style={{ color: colorTextTertiary }}>
                            Оставьте свои контактные данные, чтобы менеджер смог с вами связаться. Подтверждение не
                            требуется.
                        </Text>
                    </Row>

                    <Row style={{ flexDirection: 'column', gap: 24 }}>
                        {(contactList?.items || []).map((contact) => (
                            <Row key={contact.id}>
                                <Row
                                    align={breakpoints.md ? 'top' : 'middle'}
                                    justify="space-between"
                                    style={{ width: '100%' }}
                                >
                                    <Row style={{ gap: 4, width: 110 }}>
                                        <Icon component={() => contactsIconsMap({ iconHeight: 14 })[contact.type]} />
                                        <Text style={{ fontSize: fontSizeLG, color: colorTextTertiary }}>
                                            {contactTypesMap[contact.type]}
                                        </Text>
                                    </Row>
                                    {breakpoints.md ? (
                                        <Row style={{ flexDirection: 'column', width: 250 }}>
                                            <Text style={{ fontSize: fontSizeLG }}>{contact?.value || ''}</Text>
                                            {contact.primary_contact ? (
                                                <Text style={{ color: colorTextQuaternary, fontSize: fontSizeSM }}>
                                                    Предпочтительный способ связи
                                                </Text>
                                            ) : null}
                                        </Row>
                                    ) : null}
                                    <Row>
                                        <Button
                                            type="link"
                                            icon={<Icon component={() => <PenEdit />} />}
                                            onClick={() =>
                                                dispatch(
                                                    showModal({
                                                        modalKey: ModalKeysType.addEditContact,
                                                        params: { contactId: contact.id },
                                                    }),
                                                )
                                            }
                                        />
                                        <Button
                                            type="link"
                                            danger
                                            icon={<Icon component={() => <TrashDeleteBin />} />}
                                            onClick={() => handleDeleteContact(contact.id)}
                                        />
                                    </Row>
                                </Row>
                                {!breakpoints.md ? (
                                    <Row
                                        style={{
                                            flexDirection: 'column',
                                            width: '100%',
                                            borderBottom: `1px solid ${colorBorderSecondary}`,
                                            paddingBottom: 12,
                                        }}
                                    >
                                        <Text style={{ fontSize: fontSizeLG }}>{contact?.value || ''}</Text>
                                        {contact.primary_contact ? (
                                            <Text style={{ color: colorTextQuaternary, fontSize: fontSizeSM }}>
                                                Предпочтительный способ связи
                                            </Text>
                                        ) : null}
                                    </Row>
                                ) : null}
                            </Row>
                        ))}
                    </Row>

                    <Row
                        wrap={false}
                        justify="space-between"
                    >
                        <Row style={{ gap: 16 }}>
                            {Object.values(ContactTypeEnum).map((contactType) => (
                                <Icon
                                    key={contactType}
                                    component={() => contactsIconsMap({ iconHeight: 21 })[contactType]}
                                />
                            ))}
                        </Row>
                        <Button
                            type="link"
                            icon={<Icon component={() => <PlusAdd />} />}
                            iconPosition="end"
                            onClick={() =>
                                dispatch(
                                    showModal({
                                        modalKey: ModalKeysType.addEditContact,
                                    }),
                                )
                            }
                        >
                            Добавить
                        </Button>
                    </Row>
                </Space>
            )}
        </Card>
    );
};

export default ContactsInfoCard;
