import { useState, useEffect } from 'react';
import { PlusCircleOutlined, FileOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { StoresTable } from '@Features/seller/stores';
import { AddStoreModal } from '@Features/modals';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { goToStep, setRun } from '@Entities/joyride/model/joyrideSlice';
import { Button, Typography, Empty, Row, Grid } from '@Shared/ui';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';
import { isElementInViewport } from '@Shared/utils/isElementInViewPort';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const StoresTab = () => {
    const breakpoints = useBreakpoint();
    const dispatch = useAppDispatch();
    const [openAddStoreModal, setOpenAddStoreModal] = useState<boolean>(false);
    const { stepIndex, status } = useAppSelector((state) => state.joyride);
    const { isFetching, data: stores, isSuccess: isStoresFulFilled } = useGetStoresListQuery();
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();

    // onboarding joy-ride
    useEffect(() => {
        if (isUserFulfilled && isStoresFulFilled) {
            const onboardingStatuses = user?.onboarding_statuses || [];
            const hasActiveIntegration = !!(stores?.items || []).find((store) => store.active);
            if (!onboardingStatuses.includes(SellerOnboardingStatuses.STORE_CREATION)) {
                if (stores?.items?.length) {
                    addOnboardingStatus({ status: SellerOnboardingStatuses.STORE_CREATION });
                } else {
                    if (status !== SellerOnboardingStatuses.STORE_CREATION) {
                        dispatch(setRun(true));
                        dispatch(goToStep({ status: SellerOnboardingStatuses.STORE_CREATION }));
                    }
                }
            } else if (!onboardingStatuses.includes(SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION)) {
                if (hasActiveIntegration) {
                    addOnboardingStatus({ status: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION });
                } else {
                    if (stepIndex !== 2) {
                        const stepTarget = document.querySelector('#joyride-setup-integration-button');
                        dispatch(setRun(false));
                        if (stepTarget && !isElementInViewport(stepTarget)) {
                            stepTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
                            setTimeout(() => {
                                dispatch(setRun(true));
                                dispatch(
                                    goToStep({
                                        status: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION,
                                        order: 1,
                                    }),
                                );
                            }, 500);
                        } else {
                            dispatch(setRun(true));
                            dispatch(
                                goToStep({
                                    status: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION,
                                    order: 1,
                                }),
                            );
                        }
                    }
                }
            }
        }
    }, [
        addOnboardingStatus,
        dispatch,
        isStoresFulFilled,
        isUserFulfilled,
        status,
        stepIndex,
        stores?.items,
        stores?.items?.length,
        user,
    ]);

    const handleClickAddStore = () => {
        sendYmEvent({
            name: 'click_add_store',
            o1: 'account;advertiser;store',
            o2: 'store',
            advertiserId: user?.seller?.id,
            keycloakId: user?.id,
        });

        setOpenAddStoreModal(true);
    };

    const renderAddStoreButton = (ghost: boolean, id?: string) => (
        <Button
            icon={<PlusCircleOutlined />}
            iconPosition="start"
            type="primary"
            ghost={ghost}
            onClick={handleClickAddStore}
            id={id}
        >
            Добавить магазин
        </Button>
    );

    const EmptyTableView = (
        <Empty
            image={<FileOutlined />}
            imageStyle={{ height: 22 }}
            description={null}
        >
            <Title level={2}>Здесь пока пусто</Title>
            <Row
                justify="center"
                style={{ marginBottom: 24 }}
            >
                <Text type="secondary">В этом разделе могут храниться ваши магазины.</Text>
            </Row>
            {renderAddStoreButton(false)}
        </Empty>
    );

    return (
        <>
            <Row
                justify="space-between"
                align="top"
                style={{ columnGap: 16, rowGap: 24, flexDirection: breakpoints.lg ? 'row' : 'column-reverse' }}
                wrap={false}
            >
                <Text type="secondary">
                    В этом разделе хранится список ваших магазинов и маркетплейсов подключенных к ним.{' '}
                </Text>
                {renderAddStoreButton(true, 'joyride-add-store-button')}
            </Row>
            <StoresTable
                data={stores?.items || []}
                isLoading={isFetching}
                EmptyTableView={EmptyTableView}
            />
            <AddStoreModal
                open={openAddStoreModal}
                setOpen={setOpenAddStoreModal}
            />
        </>
    );
};
export default StoresTab;
