import { useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';

import { Button, Input, Typography, Form, message, Upload } from '@Shared/ui';
import { ModalForm } from '@Features/shared';
import { uploadImageRequest } from '@Shared/utils/uploadImage';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { useCreateANewStoreMutation, StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import type { UploadProps } from 'antd';

import './styles.scss';

const { Dragger } = Upload;
const { Text, Title } = Typography;

type AddStoreModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
};

const AddStoreModal = ({ open, setOpen }: AddStoreModalProps) => {
    const [form] = Form.useForm();
    const [createStore, { isLoading, error: addStoreError }] = useCreateANewStoreMutation();
    const { data: user } = useGetTheUserInformationQuery();

    const handleSubmit = async (formValues: StoreDto3) => {
        try {
            const data = await createStore({
                storeDto: formValues,
                notificationOptions: {
                    successMessage: 'Магазин успешно добавлен',
                    errorMessage: 'Ошибка при добавлении магазина',
                },
            }).unwrap();
            setOpen(false);
            form.resetFields();

            if (data?.id) {
                sendYmEvent({
                    name: 'send_add_store_success',
                    o1: 'account;advertiser;store',
                    o2: 'store',
                    advertiserId: user?.seller?.id,
                    keycloakId: user?.id,
                    advcampaignId: data.id,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const errors = normalizeErrorsObject(addStoreError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [addStoreError, form]);

    const draggerProps: UploadProps = {
        name: 'file',
        maxCount: 1,
        accept: 'image/png, image/svg+xml, image/jpeg',
        customRequest: uploadImageRequest,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                form.setFieldValue('logo', info.file.response);
            } else if (status === 'error') {
                message.error(`Ошибка загрузки файла ${info.file.name}.`);
            }
        },
    };

    const handleCloseModal = () => {
        form.resetFields();
        setOpen(false);
    };

    return (
        <ModalForm
            formKey="add-store-form"
            setOpen={setOpen}
            modalProps={{
                forceRender: true,
                open,
                title: (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добавить магазин
                    </Title>
                ),
                footer: null,
            }}
            formProps={{ form: form }}
            handleSubmit={handleSubmit}
            handleClose={handleCloseModal}
        >
            <Text
                type="secondary"
                style={{ marginBottom: 8 }}
            >
                Чтобы добавить новый магазин в кабинет, нам нужно немного узнать о нем. Заполните поля ниже.
            </Text>
            <Form.Item
                label="Название магазина"
                name="title"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                extra="Это название будет отображаться в каталоге"
            >
                <Input placeholder="Укажите название" />
            </Form.Item>
            <Form.Item
                label="Логотип"
                name="logo"
                extra="Используйте файлы формата – png, jpg, svg. Не более 5 Мб."
            >
                <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Щелкните или перетащите файл в эту область для загрузки</p>
                    <p className="ant-upload-hint">Поддержка одиночной или массовой загрузки.</p>
                </Dragger>
            </Form.Item>
            <Form.Item className="action-buttons-block">
                <Button onClick={() => setOpen(false)}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >
                    Добавить
                </Button>
            </Form.Item>
        </ModalForm>
    );
};

export default AddStoreModal;
