export const queryParamsSerializer = (params) => {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
        const value = params[key];

        if (Array.isArray(value) && value.length) {
            value.forEach((item) => searchParams.append(`${key}[]`, item));
        } else {
            if (value) {
                searchParams.append(key, value);
            }
        }
    });

    return searchParams.toString();
};
