// import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
// import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
// eslint-disable-next-line max-len
import {
    PublisherProductStatisticApiResponse,
    ProductStatisticDto,
} from '@Shared/api/rtkQueryApis/publisherStatistics/PublisherStatisticsApi';

type StatisticsTableProps = {
    data: PublisherProductStatisticApiResponse | undefined;
    isLoading: boolean;
    // setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
        width: 300,
    },
    {
        title: 'Ссылка на МП',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (external_link: string) => (
            <Link
                target="_blank"
                href={external_link}
            >
                {external_link}
            </Link>
        ),
    },
    {
        title: 'Переходы',
        dataIndex: 'clicks',
        key: 'clicks',
        width: 150,
        sorter: (a: ProductStatisticDto, b: ProductStatisticDto) => a.clicks - b.clicks,
    },
    {
        title: 'Конверсии',
        dataIndex: 'commissions',
        key: 'commissions',
        width: 150,
        defaultSortOrder: 'descend',
        sorter: (a: ProductStatisticDto, b: ProductStatisticDto) => a.commissions - b.commissions,
    },
    {
        title: 'Сумма комиссий',
        dataIndex: 'amount',
        key: 'amount',
        width: 180,
        render: (amount: string) => `${amount} ₽`,
        sorter: (a: ProductStatisticDto, b: ProductStatisticDto) => a.amount - b.amount,
    },
];

const StatisticsTable = ({ data, isLoading }: StatisticsTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            columns={columns}
            pagination={false}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас нет данных для отображения статистики</Text>,
                }),
            }}
        />
    );
};

export default StatisticsTable;
