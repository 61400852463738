import { useEffect, ReactNode, SetStateAction, Dispatch } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

import { Button, Col } from '@Shared/ui';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';
import { StoreDto3, MarketplaceStoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores';

import MarketplacesListBlock from './MarketplacesListBlock';
import AboutStoreBlock from './AboutStoreBlock';
import './styles.scss';

type StoreDetailsFormProps = {
    setDrawerContentType: (arg1: DrawerKeys) => void;
    setSelectedMarketplace: Dispatch<SetStateAction<MarketplaceStoreDto3 | null>>;
    store: StoreDto3 | undefined;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
    setDrawerExtraContent: (arg1: ReactNode) => void;
    setDrawerTitle: (arg1: ReactNode) => void;
};

const StoreDetailsForm = ({
    setDrawerContentType,
    setSelectedMarketplace,
    setModalType,
    setModalOpen,
    store,
    setDrawerExtraContent,
    setDrawerTitle,
}: StoreDetailsFormProps) => {
    useEffect(() => {
        setDrawerExtraContent(<></>);
    }, [setDrawerExtraContent]);

    useEffect(() => {
        setDrawerTitle(<>Интеграции</>);
    }, [setDrawerTitle]);

    return (
        <>
            <Col style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <AboutStoreBlock
                    storeData={store}
                    setDrawerContentType={setDrawerContentType}
                />
                <MarketplacesListBlock
                    marketplaceStores={store?.marketplaceStores || []}
                    setDrawerContentType={setDrawerContentType}
                    setSelectedMarketplace={setSelectedMarketplace}
                    setDrawerTitle={setDrawerTitle}
                />
                <Button
                    icon={<PlusCircleOutlined />}
                    iconPosition="start"
                    type="link"
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('newIntegration');
                    }}
                >
                    Добавить новую интеграцию
                </Button>
            </Col>
            {/* <Flex
                vertical
                className="store-details__delete-button"
            >
                <Button
                    danger
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('deleteStore');
                    }}
                >
                    Удалить магазин
                </Button>
            </Flex> */}
        </>
    );
};

export default StoreDetailsForm;
