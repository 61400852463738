import { FC } from 'react';

import { Space, Row, Typography, Divider, Descriptions, Flex, Button } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/publisherPromotions';
import { CopyCardValue, AdMarkingBlock } from '@Features/shared';
import { formatPrice } from '@Shared/utils/formatPrice';
import { FAKE_LINK } from '@Shared/constants';
import { MPTitleCell } from '@Features/shared/TableCells';
import { useExport } from '@Shared/hooks';

import { PeriodCell } from '../sellersPromotionsTable/cells';

const { Text, Title } = Typography;

type PromotionDetailsProps = {
    promotion: PromotionDto2;
    sourceId: string;
};

const BASE_URL = process.env.SERVER_URL;

export const SellersPromotionDetails: FC<PromotionDetailsProps> = ({ promotion, sourceId }) => {
    const { handleDownload } = useExport();

    const handleClickDownload = () => {
        if (promotion?.id) {
            handleDownload(
                `${BASE_URL}/api/publisher/v1/source/${sourceId}/promotion/personal/${promotion.id}/export/`,
                'promotion_products_export.csv',
            );
        }
    };

    const detailsKeyValueData = [
        { key: 'Период', value: <PeriodCell promotion={promotion} /> },
        {
            key: 'Скидка',
            value: (
                <Text>{formatPrice({ price: promotion?.discount_value || 0, feeType: promotion.discount_type })}</Text>
            ),
        },
    ];

    return (
        <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: '100%' }}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={20}
            >
                <Row style={{ gap: 8, flexDirection: 'column' }}>
                    <Title
                        level={4}
                        style={{ margin: 0 }}
                    >
                        {promotion.title || ''}
                    </Title>
                    <div dangerouslySetInnerHTML={{ __html: promotion?.description || '' }}></div>
                </Row>
                <Row style={{ gap: 8 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Витрина акции
                    </Title>
                    <CopyCardValue
                        buttonProps={{ type: 'primary' }}
                        cardValue={promotion.landing_link || FAKE_LINK}
                        className={promotion.landing_link ? '' : 'blurred-link-block'}
                        copyMessageText="Ссылка на сайт акции успешно скопирована"
                    />
                </Row>
                {promotion.coupon ? (
                    <Row style={{ gap: 8 }}>
                        <Title
                            level={5}
                            style={{ margin: 0 }}
                        >
                            Промокод
                        </Title>
                        <CopyCardValue
                            buttonProps={{ type: 'primary' }}
                            cardValue={promotion.coupon || ''}
                            className={promotion.coupon ? '' : 'blurred-link-block'}
                            copyMessageText="Промокод успешно скопирован"
                        />
                    </Row>
                ) : null}
                <Row>
                    <Descriptions
                        column={1}
                        title={
                            <Title
                                level={5}
                                style={{ marginTop: 0 }}
                            >
                                Детали
                            </Title>
                        }
                    >
                        {detailsKeyValueData.map((item, index) => (
                            <Descriptions.Item
                                label={item.key}
                                key={index}
                                contentStyle={{ justifyContent: 'flex-end' }}
                            >
                                {item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Row>
                <AdMarkingBlock
                    legalText={promotion.legal_text || ''}
                    tooltipInfoText="Согласно ФЗ О рекламе необходимо маркировать рекламные материалы. 
                                    Для соблюдения закона пожалуйста, укажите юридические данные, которые указаны
                                     в деталях каждой акции, при размещении рекламного контента"
                />
                <Row style={{ flexDirection: 'column', marginBottom: 24 }}>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Магазин
                    </Title>
                    <Row style={{ gap: 16 }}>
                        <Divider style={{ margin: 0 }} />
                        <MPTitleCell marketplaceStore={promotion?.marketplace_store} />
                        <Divider style={{ margin: 0 }} />
                    </Row>
                    <Text>
                        Вы можете{' '}
                        <Button
                            type="link"
                            onClick={handleClickDownload}
                            style={{ padding: 0 }}
                        >
                            скачать
                        </Button>{' '}
                        полный список товаров и своих комиссий по ним в CSV
                    </Text>
                </Row>
            </Space>
        </Flex>
    );
};
