import { useDispatch } from 'react-redux';

import { Button } from '@Shared/ui';
import { MailboxDownload } from '@Shared/assets/images/icons';
// import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
// import { queryParamsSerializer } from '@Shared/utils/queryParamsSerializer';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { showModal } from '@Entities/modals/model/modalsSlice';

// const BASE_URL = process.env.SERVER_URL;

const ExportButton = ({ sourceId }: { sourceId: string }) => {
    const { data: user } = useGetTheUserInformationQuery();
    const dispatch = useDispatch();
    // const handleDownload = async () => {
    //     const params = queryParamsSerializer({ source: sourceId });
    //     const response = await fetch(`${BASE_URL}/api/publisher/v1/product/export/?${params.toString()}`, {
    //         credentials: 'include',
    //     });
    //     if (response.ok) {
    //         const blob = await response.blob();
    //         const downloadUrl = URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = downloadUrl;
    //         link.download = 'products_export.csv';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         URL.revokeObjectURL(downloadUrl);
    //     } else {
    //         dispatch(
    //             showNotification({
    //                 componentProps: {
    //                     type: NotificationType.Error,
    //                     message: 'Произошла ошибка при попытке экспорта продуктов',
    //                 },
    //             }),
    //         );
    //     }
    // };

    const handleOpenExportModal = () => {
        dispatch(
            showModal({
                modalKey: ModalKeysType.exportPublisherProducts,
                params: { sourceId },
            }),
        );
    };

    return (
        <Button
            onClick={handleOpenExportModal}
            disabled={!user?.publisher?.legalInfo}
            icon={<MailboxDownload />}
        />
    );
};

export default ExportButton;
