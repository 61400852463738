import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        sellerStatisticsByProducts: build.query<
            SellerStatisticsByProductsApiResponse,
            SellerStatisticsByProductsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/statistics/product/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerStatisticsApi };
export type SellerStatisticsByProductsApiResponse = /** status 200 Resource response */ ProductStatisticListDto2;
export type SellerStatisticsByProductsApiArg = void;
export type ProductStatisticDto2 = {
    store_title: string;
    marketplace_title: string;
    product_sku: string;
    product_title: string;
    clicks: number;
    commissions: number;
    amount: number;
};
export type ProductStatisticListDto2 = {
    items?: ProductStatisticDto2[];
};
export const { useSellerStatisticsByProductsQuery } = injectedRtkApi;
