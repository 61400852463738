import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAppPrivateNotificationUnreadcount: build.query<
            GetAppPrivateNotificationUnreadcountApiResponse,
            GetAppPrivateNotificationUnreadcountApiArg
        >({
            query: () => ({ url: '/api/private/notification/unread/' }),
        }),
        getNotificationList: build.query<GetNotificationListApiResponse, GetNotificationListApiArg>({
            query: (queryArg) => ({
                url: '/api/private/notification/',
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateNotificationMarkasread: build.mutation<
            PatchAppPrivateNotificationMarkasreadApiResponse,
            PatchAppPrivateNotificationMarkasreadApiArg
        >({
            query: (queryArg) => ({
                url: `/api/private/notification/${queryArg.notification}/mark-as-read/`,
                method: 'PATCH',
            }),
        }),
        deleteAppPrivateNotificationDelete: build.mutation<
            DeleteAppPrivateNotificationDeleteApiResponse,
            DeleteAppPrivateNotificationDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/private/notification/${queryArg.notification}/`, method: 'DELETE' }),
        }),
        patchAppPrivateNotificationMarkallasread: build.mutation<
            PatchAppPrivateNotificationMarkallasreadApiResponse,
            PatchAppPrivateNotificationMarkallasreadApiArg
        >({
            query: () => ({ url: '/api/private/notification/mark-as-read/', method: 'PATCH' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as NotificationsApi };
export type GetAppPrivateNotificationUnreadcountApiResponse = unknown;
export type GetAppPrivateNotificationUnreadcountApiArg = void;
export type GetNotificationListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: NotificationDto[];
};
export type GetNotificationListApiArg = {
    page?: number;
    limit?: number;
};
export type PatchAppPrivateNotificationMarkasreadApiResponse = unknown;
export type PatchAppPrivateNotificationMarkasreadApiArg = {
    notification: string;
};
export type DeleteAppPrivateNotificationDeleteApiResponse = unknown;
export type DeleteAppPrivateNotificationDeleteApiArg = {
    notification: string;
};
export type PatchAppPrivateNotificationMarkallasreadApiResponse = unknown;
export type PatchAppPrivateNotificationMarkallasreadApiArg = void;
export type NotificationDto = {
    id: string;
    subject: string;
    body: string;
    read: boolean;
    createdAt: string;
};
export const {
    useGetAppPrivateNotificationUnreadcountQuery,
    useGetNotificationListQuery,
    usePatchAppPrivateNotificationMarkasreadMutation,
    useDeleteAppPrivateNotificationDeleteMutation,
    usePatchAppPrivateNotificationMarkallasreadMutation,
} = injectedRtkApi;
