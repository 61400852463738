import Icon, { UserOutlined } from '@ant-design/icons';

import { Button, Space, Typography, Avatar, Row, Col } from '@Shared/ui';
import { StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores';
import { DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';

import './styles.scss';

const { Title } = Typography;

const AboutStoreBlock = ({
    storeData,
    setDrawerContentType,
}: {
    storeData: StoreDto3 | undefined;
    setDrawerContentType: (arg1: DrawerKeys) => void;
}) => {
    return (
        <Row
            gutter={[0, 32]}
            wrap={false}
        >
            <Col flex="auto">
                <Row>
                    <Title
                        style={{ marginTop: 0, marginBottom: 8 }}
                        level={3}
                    >
                        {storeData?.title || ''}
                    </Title>
                </Row>
                <Space style={{ marginTop: 8, marginLeft: -16 }}>
                    <Button
                        type="link"
                        onClick={() => setDrawerContentType('editStore')}
                    >
                        Изменить
                    </Button>
                </Space>
            </Col>
            <Col
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                flex="80px"
            >
                <Avatar
                    size={40}
                    shape="square"
                    icon={
                        storeData?.logo ? (
                            <Icon
                                style={{ width: 38, height: 38 }}
                                component={() => (
                                    <img
                                        style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                                        src={storeData?.logo || ''}
                                        alt="store-logo"
                                    />
                                )}
                            />
                        ) : (
                            <UserOutlined />
                        )
                    }
                />
            </Col>
        </Row>
    );
};

export default AboutStoreBlock;
