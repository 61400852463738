import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCountryList: build.query<GetCountryListApiResponse, GetCountryListApiArg>({
            query: () => ({ url: '/api/country/' }),
        }),
        uploadANewFile: build.mutation<UploadANewFileApiResponse, UploadANewFileApiArg>({
            query: () => ({ url: '/api/private/file/upload/', method: 'POST' }),
        }),
        getProductCategoriesList: build.query<GetProductCategoriesListApiResponse, GetProductCategoriesListApiArg>({
            query: () => ({ url: '/api/product-category/' }),
        }),
        getPaymentTypesList: build.query<GetPaymentTypesListApiResponse, GetPaymentTypesListApiArg>({
            query: () => ({ url: '/api/seller/v1/payment-types/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as CommonApi };
export type GetCountryListApiResponse = /** status 200 Get country list */ CountryListDto;
export type GetCountryListApiArg = void;
export type UploadANewFileApiResponse = /** status 201 Resource created */ FileDto;
export type UploadANewFileApiArg = void;
export type GetProductCategoriesListApiResponse = /** status 200 Get product categories list */ ProductCategoryDtoList;
export type GetProductCategoriesListApiArg = void;
export type GetPaymentTypesListApiResponse = /** status 200 Get payment types list */ PaymentTypeList;
export type GetPaymentTypesListApiArg = void;
export type CountryDto = {
    id: string;
    title: string;
};
export type CountryListDto = {
    items?: CountryDto[];
};
export type FileDto = {
    fileName: string;
};
export type ProductCategoryDto = {
    id?: number | null;
    title: string | null;
};
export type ProductCategoryDtoList = {
    items?: ProductCategoryDto[];
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum5;
};
export type PaymentTypeList = {
    items?: PaymentType[];
};
export enum PaymentTypeEnum5 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
}
export const {
    useGetCountryListQuery,
    useUploadANewFileMutation,
    useGetProductCategoriesListQuery,
    useGetPaymentTypesListQuery,
} = injectedRtkApi;
