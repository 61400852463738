import { ContactTypeEnum } from '@Shared/api/rtkQueryApis/contacts';

export const contactTypesMap = {
    [ContactTypeEnum.Telegram]: 'Telegram',
    [ContactTypeEnum.Whatsapp]: 'WhatsApp',
    [ContactTypeEnum.Phone]: 'Телефон',
    [ContactTypeEnum.Email]: 'Email',
};

export const contactInputPlaceholders = {
    [ContactTypeEnum.Telegram]: 'Ссылка, юзернейм или номер телефона',
    [ContactTypeEnum.Whatsapp]: 'Укажите номер телефона',
    [ContactTypeEnum.Phone]: 'Укажите номер телефона',
    [ContactTypeEnum.Email]: 'Укажите email',
};
