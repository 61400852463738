import { MoreOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

import { Button } from '@Shared/ui';
import { DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';

import type { MenuProps } from 'antd';

type ActionsMenuProps = {
    setDrawerOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: 'deleteStore') => void;
    currentStoreId: string;
    setCurrentStoreId: (arg1: string) => void;
};

const ActionsMenu = ({
    setDrawerOpen,
    setDrawerContentType,
    // setModalOpen,
    // setModalType,
    setCurrentStoreId,
    currentStoreId,
}: ActionsMenuProps) => {
    const items: MenuProps['items'] = [
        {
            key: 'Посмотреть',
            label: 'Посмотреть',
            icon: <EyeOutlined />,
            onClick: () => {
                setDrawerOpen(true);
                setCurrentStoreId(currentStoreId);
                setDrawerContentType('storeDetails');
            },
        },
        {
            key: 'Изменить',
            label: 'Изменить',
            icon: <EditOutlined />,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                setDrawerOpen(true);
                setCurrentStoreId(currentStoreId);
                setDrawerContentType('editStore');
            },
        },
        // {
        //     type: 'divider',
        // },
        // {
        //     key: 'Удалить',
        //     label: 'Удалить',
        //     icon: <DeleteOutlined />,
        //     danger: true,
        //     onClick: ({ domEvent }) => {
        //         domEvent.stopPropagation();
        //         setCurrentStoreId(currentStoreId);
        //         setModalOpen(true);
        //         setModalType('deleteStore');
        //     },
        // },
    ];

    return (
        <Dropdown
            menu={{ items }}
            placement="bottomRight"
        >
            <Button type="text">
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
};

export default ActionsMenu;
