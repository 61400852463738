import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection';
import { SearchFilter, CheckboxFilter } from '@Features/shared/filters';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const PromotionsFilters = (props: PromotionsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Row
                wrap
                align="middle"
                style={{
                    gap: 12,
                    flexDirection: breakpoints.md ? 'row' : 'column',
                    alignItems: breakpoints.md ? 'center' : 'flex-start',
                }}
            >
                <SearchFilter<FilterObjectType>
                    {...props}
                    propName="search_string"
                    inputProps={{ placeholder: 'Артикул или наименование' }}
                />
                <CheckboxFilter<FilterObjectType>
                    label="Только продвигаемые товары"
                    propName="visible"
                    {...props}
                />
            </Row>
        </Row>
    );
};

export default PromotionsFilters;
