import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { JoinTelegramModal } from '@Features/modals';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';

import SellerJoyride from './joyride/SellerJoyride';

import { seller_app_router } from '../routes';
import Theme from '../providers/SellerThemeProvider';

const SellerApp: FC = () => {
    const { data: user, isSuccess } = useGetTheUserInformationQuery();
    const showJoinTelegramPopup =
        isSuccess &&
        !(user?.onboarding_statuses || []).includes(SellerOnboardingStatuses.SHOW_JOIN_TELEGRAM_POPUP) &&
        window.location.pathname !== '/welcome';

    return (
        <Theme>
            <SellerJoyride />
            <RouterProvider
                router={seller_app_router}
                fallbackElement={<div>Page not found</div>}
            />
            {/* {showOnboarding && <OnboardingModal />} */}
            {showJoinTelegramPopup && <JoinTelegramModal />}
        </Theme>
    );
};

export default SellerApp;
