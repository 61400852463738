import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Row, Button } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { Text, Link } = Typography;

type CopyTextLinkCellProps = {
    copyMessageText: string;
    copyValue: string;
    type: 'text' | 'link';
    href?: string;
    blurred?: boolean;
};

const CopyTextLinkCell = ({
    copyMessageText = '',
    copyValue,
    type = 'text',
    href,
    blurred = false,
}: CopyTextLinkCellProps) => {
    const { copyTextToClipboard } = useClipboard();
    const {
        token: { colorPrimary },
    } = theme.useToken();

    const handleCopyLink = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        copyTextToClipboard(copyValue || '', copyMessageText);
    };
    return (
        <Row
            wrap={false}
            align="middle"
            className={blurred ? 'blurred-link-block' : ''}
            onClick={(e) => e.stopPropagation()}
        >
            {type === 'text' ? (
                <Text
                    style={{ color: colorPrimary, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                    {copyValue}
                </Text>
            ) : (
                <Link
                    href={href || ''}
                    target="_blank"
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                    {copyValue}
                </Link>
            )}
            {copyValue ? (
                <Button
                    type="link"
                    onClick={handleCopyLink}
                    icon={<Icon component={() => <CopyItemLeftTop />} />}
                />
            ) : null}
        </Row>
    );
};

export default CopyTextLinkCell;
