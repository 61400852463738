import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getContactsList: build.query<GetContactsListApiResponse, GetContactsListApiArg>({
            query: () => ({ url: '/api/v1/contact/' }),
        }),
        addANewContact: build.mutation<AddANewContactApiResponse, AddANewContactApiArg>({
            query: (queryArg) => ({ url: '/api/v1/contact/', method: 'POST', body: queryArg.contactDto }),
        }),
        viewAContact: build.query<ViewAContactApiResponse, ViewAContactApiArg>({
            query: (queryArg) => ({ url: `/api/v1/contact/${queryArg.contact}/` }),
        }),
        putAppPrivateContactUpdate: build.mutation<
            PutAppPrivateContactUpdateApiResponse,
            PutAppPrivateContactUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/contact/${queryArg.contact}/`,
                method: 'PUT',
                body: queryArg.contactDto2,
            }),
        }),
        deleteAppPrivateContactDelete: build.mutation<
            DeleteAppPrivateContactDeleteApiResponse,
            DeleteAppPrivateContactDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/contact/${queryArg.contact}/`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as ContactsApi };
export type GetContactsListApiResponse = /** status 200 Get contacts list */ ContactDtoList;
export type GetContactsListApiArg = void;
export type AddANewContactApiResponse = /** status 201 Resource created */ ContactDto3;
export type AddANewContactApiArg = {
    contactDto: ContactDto;
};
export type ViewAContactApiResponse = /** status 200 Resource response */ ContactDto3;
export type ViewAContactApiArg = {
    contact: string;
};
export type PutAppPrivateContactUpdateApiResponse = unknown;
export type PutAppPrivateContactUpdateApiArg = {
    contact: string;
    contactDto2: ContactDto2;
};
export type DeleteAppPrivateContactDeleteApiResponse = unknown;
export type DeleteAppPrivateContactDeleteApiArg = {
    contact: string;
};
export type ContactDto3 = {
    id: string;
    type: ContactTypeEnum3;
    value: string;
    primary_contact?: boolean;
};
export type ContactDtoList = {
    items?: ContactDto3[];
};
export type ContactDto = {
    type: ContactTypeEnum;
    value: string;
    primary_contact?: boolean;
};
export type ContactDto2 = {
    type: ContactTypeEnum2;
    value: string;
    primary_contact?: boolean;
};
export enum ContactTypeEnum3 {
    Telegram = 'telegram',
    Whatsapp = 'whatsapp',
    Phone = 'phone',
    Email = 'email',
}
export enum ContactTypeEnum {
    Telegram = 'telegram',
    Whatsapp = 'whatsapp',
    Phone = 'phone',
    Email = 'email',
}
export enum ContactTypeEnum2 {
    Telegram = 'telegram',
    Whatsapp = 'whatsapp',
    Phone = 'phone',
    Email = 'email',
}
export const {
    useGetContactsListQuery,
    useAddANewContactMutation,
    useViewAContactQuery,
    usePutAppPrivateContactUpdateMutation,
    useDeleteAppPrivateContactDeleteMutation,
} = injectedRtkApi;
