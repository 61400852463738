import { SellerStoresApi } from './SellerStoresApi';

SellerStoresApi.enhanceEndpoints({
    addTagTypes: ['StoresList', 'Store', 'PromotionProductsList'],
    endpoints: {
        getStoresList: {
            providesTags: ['StoresList'],
        },
        viewTheStore: {
            providesTags: ['Store'],
        },
        createANewStore: {
            invalidatesTags: ['StoresList'],
        },
        putAppPrivateSellerStoreUpdate: {
            invalidatesTags: ['StoresList'],
        },
        removeTheStore: {
            invalidatesTags: ['StoresList'],
        },
    },
});

export * from './SellerStoresApi';
