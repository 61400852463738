import { ReactNode, useState } from 'react';

import { MarketplaceStoreDto2, useViewTheStoreQuery } from '@Shared/api/rtkQueryApis/sellerStores';
import { Drawer } from '@Shared/ui';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';
import {
    useGetAppPrivateSellerIntegrationOzonViewQuery,
    useGetExistMarketplaceIntegrationWithWildberriesQuery,
} from '@Shared/api/rtkQueryApis/sellerIntegrations';

import { StoreDetailsForm, EditStoreForm, SetupIntegrationForm } from './drawerContent';

import './styles.scss';

type StoreDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    contentType: DrawerKeys;
    storeId: string;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
};

const StoreDrawer = ({
    isDrawerOpen,
    setDrawerOpen,
    contentType,
    storeId,
    setDrawerContentType,
    setModalOpen,
    setModalType,
}: StoreDrawerProps) => {
    const [selectedMarketplace, setSelectedMarketplace] = useState<MarketplaceStoreDto2 | null>(null);
    const { data: store, isFetching: isStoreDataLoading } = useViewTheStoreQuery(
        { store: storeId },
        { skip: !storeId },
    );
    const { isFetching: isOzonIntegrationLoading } = useGetAppPrivateSellerIntegrationOzonViewQuery(
        {
            store: storeId,
        },
        { skip: !storeId },
    );
    const { isFetching: isWildberriesIntegrationLoading } = useGetExistMarketplaceIntegrationWithWildberriesQuery(
        {
            store: storeId,
        },
        { skip: !storeId },
    );
    const isIntegrationFormLoading = isOzonIntegrationLoading || isWildberriesIntegrationLoading;

    const [drawerExtraContent, setDrawerExtraContent] = useState<ReactNode>(<></>);
    const [drawerTitle, setDrawerTitle] = useState<ReactNode>(<></>);

    const isDrawerLoading =
        (isStoreDataLoading && (contentType === 'storeDetails' || contentType === 'editStore')) ||
        (isIntegrationFormLoading && contentType === 'setupIntegration');

    const renderDrawer: Record<DrawerKeys, ReactNode> = {
        storeDetails: (
            <StoreDetailsForm
                setDrawerContentType={setDrawerContentType}
                setSelectedMarketplace={setSelectedMarketplace}
                store={store}
                setModalOpen={setModalOpen}
                setModalType={setModalType}
                setDrawerExtraContent={setDrawerExtraContent}
                setDrawerTitle={setDrawerTitle}
            />
        ),
        editStore: (
            <EditStoreForm
                setOpen={setDrawerOpen}
                setDrawerContentType={setDrawerContentType}
                store={store}
                setModalOpen={setModalOpen}
                setModalType={setModalType}
                setDrawerExtraContent={setDrawerExtraContent}
                setDrawerTitle={setDrawerTitle}
            />
        ),
        setupIntegration: (
            <SetupIntegrationForm
                open={isDrawerOpen}
                setOpen={setDrawerOpen}
                setDrawerContentType={setDrawerContentType}
                storeId={storeId}
                selectedMarketplace={selectedMarketplace}
                setDrawerExtraContent={setDrawerExtraContent}
            />
        ),
        '': undefined,
    };

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isDrawerLoading}
            onClose={() => setDrawerOpen(false)}
            title={drawerTitle}
            className="stores-table__drawer"
            extra={drawerExtraContent}
        >
            {renderDrawer[contentType]}
        </Drawer>
    );
};

export default StoreDrawer;
