import { Space, Card, Typography } from '@Shared/ui';
import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { StatisticsTableBlock } from '@Features/seller/statistics';

const { Title } = Typography;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/statistics',
        title: 'Статистика',
    },
];

const StatisticsPage = () => {
    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            <Card style={{ width: '100%' }}>
                <Space
                    direction="vertical"
                    size={24}
                    style={{ width: '100%' }}
                >
                    <Title
                        level={3}
                        style={{ margin: 0 }}
                    >
                        Статистика
                    </Title>
                    <StatisticsTableBlock />
                </Space>
            </Card>
        </PageContent>
    );
};
export default StatisticsPage;
