import {
    // useGetCollectionOfThreadsQuery,
    // useGetCollectionOfMessagesForSellerQuery,
    useGetProductsListQuery,
    useGetAllPublishersQuery,
} from '@Shared/api/full';
import { useGetProductsListForCampaignQuery } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export const ENTITIES = {
    products: {
        getCollection: useGetProductsListQuery,
    },
    publishers: {
        getCollection: useGetAllPublishersQuery,
    },
    campanyProducts: {
        getCollection: useGetProductsListForCampaignQuery,
    },
    // threads: {
    //     getCollection: useGetCollectionOfThreadsQuery,
    // },
    // messages: {
    //     getCollection: useGetCollectionOfMessagesForSellerQuery,
    // },
};
