import { Space, Card, Typography, Grid, Row } from '@Shared/ui';

const { Title, Link, Text } = Typography;
const { useBreakpoint } = Grid;

const ProfileInfoBlock = () => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            wrap={false}
            style={{
                gap: 24,
                flex: breakpoints.lg ? '0 0 286px' : 'unset',
                width: breakpoints.lg ? 'auto' : '100%',
            }}
        >
            <Card style={{ flex: 1 }}>
                <Space
                    direction="vertical"
                    size="middle"
                >
                    <Title
                        type="secondary"
                        level={3}
                    >
                        FAQ
                    </Title>
                    <Text>Что такое Mitgo ID?</Text>
                    <Text>Зачем нужны контактные данные?</Text>
                    <Text>Хелпцентр для пользователей Такпродам</Text>
                    <Link
                        onClick={() => {
                            window.location.href = `mailto:${process.env.SUPPORT_EMAIL}`;
                        }}
                    >
                        Нужна помощь?
                    </Link>
                </Space>
            </Card>
        </Row>
    );
};

export default ProfileInfoBlock;
