import { Select, Grid } from '@Shared/ui';
import { FilterObjectType, PromotionTypeOptions } from '@Features/seller/promotions/personalPromotionsTab/model/types';

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};
const { useBreakpoint } = Grid;

const PromotionTypesFilter = ({ filterObject, setFilterObject }: ProductsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Select
            style={{ width: breakpoints.md ? 200 : '100%' }}
            mode="multiple"
            allowClear
            disabled={!PromotionTypeOptions.length}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={PromotionTypeOptions}
            onChange={(option) => setFilterObject({ ...filterObject, promotionTypes: option, page: 1 })}
            value={filterObject.promotionTypes || []}
            placeholder="Тип акции"
        />
    );
};

export default PromotionTypesFilter;
