import { theme } from 'antd';

import { Form, Card, Typography, InputNumber, Input, Row, Grid } from '@Shared/ui';

const { Title, Link } = Typography;
const { useBreakpoint } = Grid;

const MainDetailsCard = () => {
    const breakpoints = useBreakpoint();
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    return (
        <Card style={{ width: '100%' }}>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Название кампании и бюджет
            </Title>
            <Row
                align="bottom"
                wrap={breakpoints.md ? false : true}
                style={{ columnGap: 20 }}
            >
                <Form.Item
                    layout="vertical"
                    label="Название кампании"
                    name="title"
                    required
                    style={{ width: '100%' }}
                >
                    <Input placeholder="Укажите название кампании" />
                </Form.Item>
                <Form.Item
                    layout="vertical"
                    label="Суточный бюджет"
                    name="daily_budget"
                    required
                    style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    // eslint-disable-next-line max-len
                    tooltip="Суточный лимит для оплаты комиссии па
блишерам. По достижении лимита мы отправим вам уведомление и сообщим паблишерам, что ссылки стали неактивными."
                >
                    <InputNumber
                        step="1"
                        min={1000}
                        addonAfter="₽"
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Row>
            <Form.Item
                layout="vertical"
                label="Цена клика"
                name={['fee_setting', 'fee_value']}
                trigger="onChange"
                style={{ width: breakpoints.md ? 'auto' : '100%' }}
                tooltip="Эта цена будет применяться автоматически к товарам, для которых не настроена уникальная цена за клик.
                 Если конкретному товару нужно задать свою цену за клик, сделайте это вручную в списке ниже."
                extra={
                    <>
                        Здесь вы устанавливаете цену клика сразу для всех ваших товаров. <br />
                        Фактически цена за клик может отличаться, так как зависит от конверсии трафика паблишера.
                        <Link> Подробнее</Link>
                    </>
                }
                required
            >
                <InputNumber
                    step="1"
                    min={1}
                    addonAfter="₽"
                    style={{ width: breakpoints.md ? 175 : '100%' }}
                />
            </Form.Item>
        </Card>
    );
};
export default MainDetailsCard;
