import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Form, Row, Button, Space, Grid, Skeleton, Col } from '@Shared/ui';
import {
    useViewTheCampaignQuery,
    CpcCampaignDto,
    CpcCampaignDto2,
    useAddACpcCampaignMutation,
    usePutAppPrivateSellerCampaignUpdatecpcMutation,
    CampaignStatusEnum,
} from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { CampaignTypeCard, campaignTypes } from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection';
import { SelectedMPStore } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/cpc/model/types';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import SelectMPstoreCard from './SelectMPstoreCard';
import ProductCampaignsCard from './ProductCampaignsCard';
import MainDetailsCard from './MainDetailsCard';

const { useBreakpoint } = Grid;

const initialCampaignProducts = [
    {
        product: null,
    },
];

const CPCform = () => {
    const [selectedMPStore, setMPstore] = useState<SelectedMPStore | null>(null);
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { slug = '' } = useParams();
    const { data: campaign, isLoading: isCampaignLoading } = useViewTheCampaignQuery({ slug }, { skip: !slug });
    const [addCPCcampaign, { isLoading: isAddingCampaign, error: isAddingError }] = useAddACpcCampaignMutation();
    const [updateCPCcampaign, { isLoading: isUpdatingCampaign, error: isUpdatingError }] =
        usePutAppPrivateSellerCampaignUpdatecpcMutation();

    const isCampaignActive = campaign?.status === CampaignStatusEnum.Active;
    const isAddForm = !slug;

    const isFormDisabled = false;

    useEffect(() => {
        if (campaign) {
            const formData: CpcCampaignDto = {
                title: campaign.title,
                marketplace_store: campaign.marketplace_store?.id || '',
                fee_setting: {
                    fee_value: campaign.fee_setting?.fee_value || 1,
                },
                daily_budget: campaign.daily_budget,
                product_campaigns: campaign?.product_campaigns?.map((productCampaign) => ({
                    product: productCampaign.product?.id,
                    commission: productCampaign.fee_setting?.fee_value,
                })),
            };
            form.setFieldsValue(formData);
            if (campaign.marketplace_store) {
                setMPstore({
                    marketplaceSlug: campaign.marketplace_store.marketplace?.slug || '',
                    storeId: campaign.marketplace_store.store?.id || '',
                    mpStoreId: campaign.marketplace_store?.id || '',
                });
            }
        }
    }, [campaign, form]);

    useEffect(() => {
        const errObject = isAddingError || isUpdatingError;
        if (errObject) {
            const errors = normalizeErrorsObject(errObject?.data?.errors);
            const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
            form.setFields(formErrors);
        }
    }, [form, isAddingError, isUpdatingError]);

    const handleNavigateToCampaignsPage = () => {
        navigate('/product-promotion/campaigns');
    };

    const initialValues = {
        fee_setting: {
            fee_value: 1,
        },
        daily_budget: 1000,
        product_campaigns: initialCampaignProducts,
    };

    const onFinish = async (formValues: CpcCampaignDto2) => {
        try {
            if (slug) {
                const res = await updateCPCcampaign({
                    cpcCampaignDto2: formValues,
                    notificationOptions: {
                        successMessage: 'Рекламная кампания успешно обновлена',
                    },
                    slug,
                });
                if (!res.error) {
                    handleNavigateToCampaignsPage();
                }
            } else {
                const res = await addCPCcampaign({
                    cpcCampaignDto: formValues,
                    notificationOptions: {
                        successMessage: 'Рекламная кампания успешно сохранена',
                    },
                });
                if (!res.error) {
                    handleNavigateToCampaignsPage();
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    if (isCampaignLoading) return <Skeleton active />;

    return (
        <Form
            form={form}
            name="setup-cpc-campaign-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
            className="setup-cpc-campaign__form"
            initialValues={initialValues}
            scrollToFirstError
        >
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row
                    style={{ gap: 20 }}
                    wrap={!breakpoints.lg}
                >
                    <Col
                        xs={24}
                        lg={{ flex: '264px' }}
                        style={{ height: 'fit-content' }}
                    >
                        <CampaignTypeCard type={{ ...campaignTypes[0], hasButton: false }} />
                    </Col>
                    <Col
                        xs={24}
                        lg={{ flex: 'auto' }}
                    >
                        <SelectMPstoreCard
                            onChange={(val, option) => {
                                form.setFieldValue(['marketplace_store'], val);
                                isAddForm && form.setFieldValue(['product_campaigns'], initialCampaignProducts);
                                setMPstore({
                                    marketplaceSlug: option?.marketplaceSlug || '',
                                    storeId: option?.store?.id || '',
                                    mpStoreId: val,
                                });
                            }}
                            value={selectedMPStore?.mpStoreId || ''}
                            isEditForm={!isAddForm}
                        />
                    </Col>
                </Row>
                <MainDetailsCard />
                <ProductCampaignsCard
                    selectedMPStore={selectedMPStore}
                    form={form}
                    isCampaignActive={isCampaignActive}
                />
                <Form.Item>
                    <Row
                        className="action-buttons-block"
                        style={{
                            justifyContent: 'space-between',
                            gap: 10,
                            flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                        }}
                    >
                        <Button
                            onClick={handleNavigateToCampaignsPage}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                        >
                            Отменить
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isFormDisabled}
                            loading={isAddingCampaign || isUpdatingCampaign}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                        >
                            {slug ? 'Сохранить изменения' : 'Запустить кампанию'}
                        </Button>
                    </Row>
                </Form.Item>
            </Space>
        </Form>
    );
};
export default CPCform;
