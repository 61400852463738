import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/publisher/promotions/sellersPromotionsTab/model/types';
// eslint-disable-next-line max-len
import SellersPromotionDetailsDrawer from '@Features/publisher/promotions/sellersPromotionsTab/sellersPromotionsDrawer/SellersPromotionDetailsDrawer';
import { useUpdateSearchParams } from '@Shared/hooks';
// eslint-disable-next-line max-len
import { useGetPersonalPromotionsListWithLinksBySourceQuery } from '@Shared/api/rtkQueryApis/publisherPromotions';
import { SourceSelectBlock } from '@Features/shared';

import SellersPromotionsTable from './sellersPromotionsTable/SellersPromotionsTable';
import SellersPromotionsFilters from './sellersPromotionsFilters/SellersPromotionsFilters';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    marketplaces: searchParams.getAll(FilterEnum.MARKETPLACES),
    favorite: Boolean(searchParams.get(FilterEnum.FAVORITE)),
    promotionTypes: searchParams.getAll(FilterEnum.PROMOTION_TYPES),
});

const PromotionsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [currentPromotionId, setPromotionId] = useState('');
    const [selectedSourceId, setSourceId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data: promotions, isFetching: isPromotionsLoading } = useGetPersonalPromotionsListWithLinksBySourceQuery(
        {
            source: selectedSourceId,
            page: filterObject.page,
            limit: filterObject.limit,
            favorite: filterObject?.favorite || undefined,
            marketplaces: filterObject?.marketplaces?.length ? filterObject.marketplaces : undefined,
            promotionTypes: filterObject?.promotionTypes?.length ? filterObject.promotionTypes : undefined,
        },
        { skip: !selectedSourceId },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <SourceSelectBlock
                    setSourceId={setSourceId}
                    selectedSourceId={selectedSourceId}
                />
                <SellersPromotionsFilters
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    selectedSourceId={selectedSourceId}
                />
                <SellersPromotionsTable
                    data={promotions}
                    isLoading={isPromotionsLoading}
                    setFilterObject={setFilterObject}
                    setPromotionId={setPromotionId}
                    setDrawerOpen={setDrawerOpen}
                    selectedSourceId={selectedSourceId}
                />
            </Space>
            <SellersPromotionDetailsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                promotionId={currentPromotionId}
                selectedSourceId={selectedSourceId}
            />
        </>
    );
};
export default PromotionsTableBlock;
