import { type SelectProps } from 'antd';

import { Grid, Select } from '@Shared/ui';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { useBreakpoint } = Grid;

type MarketplaceStoreFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    selectProps?: SelectProps;
};

const MarketplaceStoreFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'marketplaceStoreId' as keyof FilterObjectType,
    selectProps = {},
}: MarketplaceStoreFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const { data: stores, isLoading } = useGetStoresListQuery();

    const allMarketplaceStores = (stores?.items || []).flatMap((store) =>
        store.marketplaceStores.map((marketplaceStore) => ({
            value: marketplaceStore.id,
            label: `${store.title} - ${marketplaceStore.marketplace_title}`,
        })),
    );
    const isValueExistInOptions = allMarketplaceStores.find((option) => option.value === filterObject[propName]);

    return (
        <Select
            placeholder="Выберите маркетплейс"
            onChange={(option) => setFilterObject({ ...filterObject, [propName]: option, page: 1 })}
            value={isValueExistInOptions ? filterObject[propName] : ''}
            style={{ width: breakpoints.md ? 240 : '100%' }}
            options={[{ value: '', label: 'Все интеграции' }, ...allMarketplaceStores]}
            loading={isLoading}
            {...selectProps}
        />
    );
};

export default MarketplaceStoreFilter;
