import { useCallback } from 'react';
import { type SelectProps } from 'antd';

import { Grid, Select } from '@Shared/ui';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { useBreakpoint } = Grid;

type SellerStoreFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    selectProps?: SelectProps;
};

const SellerStoreFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'storeId' as keyof FilterObjectType,
    selectProps = {},
}: SellerStoreFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const { data: stores, isLoading } = useGetStoresListQuery();

    const storesOptions = (stores?.items || []).map((store) => ({
        value: store.id,
        label: store.title,
    }));

    const getValue = useCallback(() => {
        if (selectProps.mode === 'multiple') {
            return isLoading ? [] : filterObject[propName];
        }
        return isLoading ? '' : filterObject[propName];
    }, [filterObject, isLoading, propName, selectProps.mode]);

    return (
        <Select
            placeholder="Выберите магазин"
            onChange={(option) => setFilterObject({ ...filterObject, [propName]: option, page: 1 })}
            value={getValue()}
            style={{ width: breakpoints.md ? 240 : '100%' }}
            options={[
                ...(selectProps.mode === 'multiple' ? [] : [{ value: '', label: 'Все магазины' }]),
                ...storesOptions,
            ]}
            loading={isLoading}
            {...selectProps}
        />
    );
};

export default SellerStoreFilter;
