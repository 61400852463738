import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Row, Typography } from '@Shared/ui';
import { Star } from '@Shared/assets/images/icons';
import { type ProductCampaignDto } from '@Shared/api/rtkQueryApis/publisherSources';

const { Text } = Typography;

const RatingCell = ({ productCampaign }: { productCampaign: ProductCampaignDto }) => {
    const {
        token: { colorTextTertiary, fontSizeSM },
    } = theme.useToken();

    return (
        <>
            <Row style={{ gap: 4, alignItems: 'flex-start' }}>
                <Icon component={() => <Star style={{ opacity: '0.45' }} />} />
                <Text>{productCampaign.product?.price}</Text>
            </Row>
            <Text style={{ color: colorTextTertiary, fontSize: fontSizeSM }}>45 558 оценок</Text>
        </>
    );
};

export default RatingCell;
