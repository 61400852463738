import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { FC, MouseEvent } from 'react';

import { QuestionCircle } from '@Shared/assets/images/icons';

type QuestionTooltipProps = { title: string; tooltipPlacement?: string; iconSize?: string; textAlign?: string };

const QuestionTooltip: FC<QuestionTooltipProps> = ({
    title,
    tooltipPlacement = 'top',
    iconSize = '12px',
    textAlign = 'center',
}) => {
    const handleStopPropagation = (event: MouseEvent<HTMLSpanElement>) => {
        if (event?.stopPropagation) {
            event.stopPropagation();
        }
    };
    return (
        <Tooltip
            title={title}
            overlayStyle={{ maxWidth: '350px' }}
            overlayInnerStyle={{ textAlign: textAlign }}
            placement={tooltipPlacement}
        >
            <Icon
                onClick={handleStopPropagation}
                component={() => <QuestionCircle style={{ opacity: '0.45', width: iconSize }} />}
            />
        </Tooltip>
    );
};

export default QuestionTooltip;
