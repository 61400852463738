import { useDispatch } from 'react-redux';
import { theme, FormInstance } from 'antd';

import { Form, Card, Typography, Row, InputNumber, Button, Space, Grid, Col } from '@Shared/ui';
import { SelectedMPStore } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/cpc/model/types';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { showModal } from '@Entities/modals/model/modalsSlice';

import SearchProductDropdown from './SearchProductsDropdown';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type ProductCampaignsCardProps = {
    selectedMPStore: SelectedMPStore | null;
    form: FormInstance;
    isCampaignActive: boolean;
};

const ProductCampaignsCard = ({ selectedMPStore, form, isCampaignActive }: ProductCampaignsCardProps) => {
    const campaignFeeValue = Form.useWatch(['fee_setting', 'fee_value'], form);
    const dispatch = useDispatch();
    const breakpoints = useBreakpoint();
    const {
        token: { colorTextSecondary, margin },
    } = theme.useToken();

    const validateNoDuplicates = (_, value) => {
        const productCampaigns = form.getFieldValue('product_campaigns') || [];
        const duplicateIndices = productCampaigns.reduce((acc, item, index) => {
            if (item && item.product === value) {
                acc.push(index);
            }
            return acc;
        }, []);

        // Check if the current product is duplicated
        if (duplicateIndices.length > 1) {
            return Promise.reject(new Error('Нельзя выбрать один и тот же товар несколько раз'));
        }
        return Promise.resolve();
    };

    return (
        <Card>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={16}
            >
                <Title
                    type="secondary"
                    style={{ fontSize: 18, color: colorTextSecondary, margin: 0 }}
                    level={4}
                >
                    Товары, которые будут участвовать в РК
                </Title>
                <Text type="secondary">
                    Выберите товары, которые будут участвовать в рекламной кампании. Вы можете настроить отдельную
                    комиссию для определенных товаров при необходимости
                </Text>
                <Form.List name="product_campaigns">
                    {(fields, { add, remove }) => (
                        <div style={{ display: 'flex', rowGap: 20, flexDirection: 'column' }}>
                            {fields.map((field, index) => (
                                <Row
                                    wrap={!breakpoints.md}
                                    style={{ columnGap: 12 }}
                                    key={field.key}
                                >
                                    <Col flex="auto">
                                        <Form.Item
                                            label={`Товар ${index + 1}`}
                                            name={[field.name, 'product']}
                                            rules={[
                                                { required: true, message: 'Это поле обязательно к заполнению' },
                                                { validator: validateNoDuplicates },
                                            ]}
                                            // value={field?.value?.product} // Pass the current value of the field
                                            extra="Товар можно искать по артикулу или названию"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <SearchProductDropdown
                                                selectedMPStore={selectedMPStore}
                                                isDisabled={
                                                    isCampaignActive &&
                                                    !form.getFieldValue(['product_campaigns', index, 'newFormListItem'])
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: breakpoints.md ? 120 : '100%' }}>
                                        <Form.Item
                                            label="Цена клика"
                                            name={[field.name, 'commission']}
                                            style={{ width: breakpoints.md ? 120 : '100%', marginBottom: 0 }}
                                            tooltip="Здесь вы можете настроить уникальную цену за клик для каждого товара"
                                        >
                                            <InputNumber
                                                step="1"
                                                min={1}
                                                addonAfter="₽"
                                                placeholder={campaignFeeValue}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {(fields.length > 1 && !isCampaignActive) ||
                                    (isCampaignActive &&
                                        form.getFieldValue(['product_campaigns', index, 'newFormListItem'])) ? (
                                        <Button
                                            onClick={() =>
                                                !form.getFieldValue(['product_campaigns', index, 'newFormListItem'])
                                                    ? dispatch(
                                                          showModal({
                                                              modalKey: ModalKeysType.deleteCPCproductCampaign,
                                                              params: { remove: remove, fieldName: field.name },
                                                          }),
                                                      )
                                                    : remove(field.name)
                                            }
                                            danger
                                            style={{
                                                width: breakpoints.md ? 90 : '100%',
                                                marginTop: breakpoints.md ? 30 : 12,
                                            }}
                                        >
                                            Удалить
                                        </Button>
                                    ) : null}
                                </Row>
                            ))}
                            <Button
                                onClick={() => add({ newFormListItem: true })}
                                block
                                style={{ marginTop: margin }}
                            >
                                + Добавить еще товар
                            </Button>
                        </div>
                    )}
                </Form.List>
            </Space>
        </Card>
    );
};
export default ProductCampaignsCard;
