import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Flex, Typography, Skeleton, Row, Tooltip } from '@Shared/ui';
import { getBalanceColor } from '@Shared/utils/finances/getBalanceColor';
import { getBalanceInfoText } from '@Shared/utils/finances/getBalanceInfoText';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { Information } from '@Shared/assets/images/icons';
import { formatPrice } from '@Shared/utils/formatPrice';

const { Title, Text } = Typography;

const BalancesHeaderItem = ({ align = 'flex-end', vertical = true }: { align?: string; vertical?: boolean }) => {
    const { data: user, isLoading } = useGetTheUserInformationQuery();

    return (
        <Flex
            vertical={vertical}
            align={align}
            justify="center"
            onClick={(e) => e.stopPropagation()}
            style={{ marginRight: 8, columnGap: 8 }}
        >
            <Text>Баланс: </Text>
            {isLoading ? (
                <Skeleton.Input active />
            ) : (
                <Row align="middle">
                    <Link to="/finances">
                        <Title
                            level={5}
                            style={{
                                margin: 0,
                                marginRight: 8,
                                fontSize: 14,
                                color: getBalanceColor(user?.balance || 0),
                            }}
                        >
                            {formatPrice({ price: user?.balance || 0 })}
                        </Title>
                    </Link>
                    <Tooltip
                        placement="bottomRight"
                        title={getBalanceInfoText(user?.balance || 0, user?.credit || 0)}
                        arrow={{ pointAtCenter: true }}
                    >
                        <Icon component={() => <Information style={{ opacity: '0.45' }} />} />
                    </Tooltip>
                </Row>
            )}
        </Flex>
    );
};
export default BalancesHeaderItem;
