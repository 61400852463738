import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Card, Row, Typography, Button } from '@Shared/ui';
import { TelegramCircle } from '@Shared/assets/images/icons';

const { Title, Text } = Typography;

const JoinTelegramCard = () => {
    const {
        token: { colorPrimaryBg, colorTextTertiary },
    } = theme.useToken();

    return (
        <Card style={{ background: colorPrimaryBg, flex: 1 }}>
            <Icon
                component={() => <TelegramCircle />}
                style={{ position: 'absolute', top: 0, right: 0 }}
            />
            <Title
                level={5}
                style={{ marginTop: 0 }}
            >
                Присоединяйся к закрытому телеграмм-каналу селлеров!
            </Title>
            <Text style={{ color: colorTextTertiary }}>
                В канале Продавцы Такпродам на твои вопросы отвечают не только менеджеры, а еще и команда разработки
                проекта.
            </Text>
            <Row
                style={{ marginTop: 20 }}
                justify="end"
            >
                <Button
                    href="https://t.me/+FPLqIgu0A_ZiY2Vi"
                    target="_blank"
                >
                    Вступить
                </Button>
            </Row>
        </Card>
    );
};

export default JoinTelegramCard;
