export function calculateDateTimeAgo(createdAt: string | Date): string {
    const now = new Date();
    const createdDate = new Date(createdAt);

    // Calculate the difference in milliseconds
    const diffMs = now.getTime() - createdDate.getTime();

    // Calculate differences in minutes, hours, days, months, and years
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    // Calculate the difference in months
    const yearsDifference = now.getFullYear() - createdDate.getFullYear();
    const monthsDifference = yearsDifference * 12 + (now.getMonth() - createdDate.getMonth());

    // Function for pluralization in Russian
    const pluralizeRu = (number: number, one: string, few: string, many: string): string => {
        const pluralRules = new Intl.PluralRules('ru', { type: 'cardinal' });
        const form = pluralRules.select(number);
        switch (form) {
            case 'one':
                return `${number} ${one}`;
            case 'few':
                return `${number} ${few}`;
            default:
                return `${number} ${many}`;
        }
    };

    if (diffDays < 30 && diffDays > 0) {
        return pluralizeRu(diffDays, 'день', 'дня', 'дней');
    } else if (monthsDifference >= 12) {
        const years = Math.floor(monthsDifference / 12);
        return pluralizeRu(years, 'год', 'года', 'лет');
    } else if (monthsDifference > 0) {
        return pluralizeRu(monthsDifference, 'месяц', 'месяца', 'месяцев');
    } else if (diffHours > 0) {
        return pluralizeRu(diffHours, 'час', 'часа', 'часов');
    } else if (diffMinutes > 0) {
        return pluralizeRu(diffMinutes, 'минута', 'минуты', 'минут');
    } else return 'Менее минуты';
}
