import { useEffect, useState } from 'react';

import { Button, Space, Modal, Typography, Image, Grid } from '@Shared/ui';
import { SubscribeEvent } from '@Shared/assets/images';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const EventPopupModal = () => {
    const breakpoint = useBreakpoint();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const eventModalKey = 'event-modal-key';

    const updateShowingModal = (day: number, count: number) => {
        localStorage.setItem(
            eventModalKey,
            JSON.stringify({
                [day]: count,
            }),
        );
    };

    useEffect(() => {
        const storageData = localStorage.getItem(eventModalKey);
        const day = new Date().getDate();
        const endTime = new Date('Fri Jan 23 2025 12:00:00 GMT+0300 (Москва, стандартное время)').getTime();

        const isEnded = new Date().getTime() > endTime;
        let count = 1;

        if (storageData) {
            count = JSON.parse(storageData)[day] || 1;
        }

        setTimeout(() => {
            if (!storageData) {
                updateShowingModal(day, 2);
            } else {
                const eventCountData = JSON.parse(storageData);
                const dayCountData = eventCountData?.[day];
                updateShowingModal(day, dayCountData ? dayCountData + 1 : 2);
            }

            if (count <= 3 && !isEnded) {
                setIsModalOpen(true);
            }
        }, 1000);
    }, [setIsModalOpen]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            closable={false}
            width={560}
            bodyProps={{
                style: {
                    margin: '-20px -24px',
                },
            }}
            footer={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 10,
                        marginTop: 8,
                    }}
                >
                    <Button
                        type="primary"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdkIIhAvqBVC0ZPlgLHUVmkFeC_WManprjftri-sBpBADmSzg/viewform?usp=sf_link"
                        target="_blank"
                        style={{ width: breakpoint.xs ? '100%' : 200 }}
                    >
                        Email
                    </Button>
                    <Button
                        type="primary"
                        href="https://t.me/TakprodamEventsBot"
                        target="_blank"
                        style={{ width: breakpoint.xs ? '100%' : 200 }}
                    >
                        Телеграм-бот
                    </Button>
                </div>
            }
        >
            <Space
                direction="vertical"
                size={20}
                style={{ width: '100%' }}
            >
                <Image
                    src={SubscribeEvent}
                    preview={false}
                    style={{
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                    }}
                />

                <Title
                    level={3}
                    style={{ margin: 0, marginBottom: 20, textAlign: 'center' }}
                >
                    Запись на вебинар
                </Title>
            </Space>
        </Modal>
    );
};

export default EventPopupModal;
