import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, theme } from 'antd';
import { UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { showModal } from '@Entities/modals/model/modalsSlice';
import { useAppDispatch } from '@Shared/hooks';
import { Flex, Button, Badge, Avatar } from '@Shared/ui';
import { userMenuItems } from '@Widgets/seller/menuItems';
import BalancesHeaderItem from '@Widgets/seller/BalancesHeaderItem';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';

import NotificationHeaderItem from '../notificationsMenu/NotificationsHeaderItem';
import RefLinkModal from '../RefLinkModal';
import './styles.scss';

const { Header } = Layout;

type DesktopPageHeaderProps = {
    menuCollapsed: boolean;
    setMenuCollapsed: (value: boolean) => unknown;
};

export default function DesktopPageHeader({ menuCollapsed, setMenuCollapsed }: DesktopPageHeaderProps) {
    const { data: user } = useGetTheUserInformationQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        token: { colorBgLayout },
    } = theme.useToken();

    const headerItems = useMemo(
        () => [
            {
                key: 'notifications',
                label: <NotificationHeaderItem />,
            },
            {
                key: 'user',
                label: false,
                id: 'joyride-user-menu-button',
                children: userMenuItems(user?.onboarding_statuses || []),
                className: 'hide-title-content',
                icon: (
                    <Badge
                        dot={!user?.onboarding_statuses?.includes(SellerOnboardingStatuses.PROFILE_PAGE)}
                        offset={[-8, 8]}
                    >
                        <Avatar
                            icon={<UserOutlined />}
                            style={{ background: colorBgLayout, color: 'black' }}
                        />
                    </Badge>
                ),
            },
        ],
        [colorBgLayout, user?.onboarding_statuses],
    );

    const onClick = (e) => {
        const item = userMenuItems(user?.onboarding_statuses || []).find((item) => item.key === e.key);
        if (item && item.href) {
            navigate(item.href);
        } else if (item && item.modal) {
            dispatch(showModal({ modalKey: item.modal }));
        }
    };
    const toggleCollapsed = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    return (
        <Header className="page-header">
            <RefLinkModal />
            <Flex
                justify="space-between"
                style={{ padding: '0 16px' }}
            >
                <div style={{ marginRight: 'auto' }}>
                    <Button
                        type="text"
                        onClick={toggleCollapsed}
                    >
                        {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </div>
                <BalancesHeaderItem />
                <div>
                    <Menu
                        selectedKeys={[]}
                        mode="horizontal"
                        items={headerItems}
                        onClick={onClick}
                        className="desktop-header-menu"
                    />
                </div>
            </Flex>
        </Header>
    );
}
