import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'antd';

import { Space, Form, Row, Button, Grid } from '@Shared/ui';
import {
    PromotionSelectorCard,
    PromotionDetailsCard,
    PromotionProductsTableCard,
} from '@Features/seller/promotions/editPersonalPromotion';
import {
    PromotionDto3,
    useAddAPersonalPromotionMutation,
    useUpdateThePersonalPromotionMutation,
    PromotionTypeEnum2,
    DiscountTypeEnum,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';
import { dayjs } from '@Shared/utils';

const { useBreakpoint } = Grid;

const PersonalPromotionForm = ({
    promotion,
    isAddPersonalPromotion = false,
}: {
    promotion?: PromotionDto3 | undefined;
    isAddPersonalPromotion?: boolean;
}) => {
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const selectedPromotionType = Form.useWatch('type', form);
    const {
        token: { paddingLG },
    } = theme.useToken();
    const [addPromotion, { isLoading: isAdding, error: isAddingError }] = useAddAPersonalPromotionMutation();
    const [updatePromotion, { isLoading: isUpdating, error: isUpdatingError }] =
        useUpdateThePersonalPromotionMutation();

    const onFinish = async (formValues) => {
        try {
            const modifiedFormData = {
                ...formValues,
                start_date: dayjs(formValues?.period[0]).format(),
                end_date: dayjs(formValues?.period[1].hour(23).minute(59).second(59)).format(),
            };
            isAddPersonalPromotion
                ? await addPromotion({
                      personalPromotionDto: modifiedFormData,
                      notificationOptions: {
                          successMessage: 'Персональное предложение успешно создано',
                      },
                  }).unwrap()
                : await updatePromotion({
                      personalPromotionDto2: modifiedFormData,
                      id: promotion?.id || '',
                      notificationOptions: {
                          successMessage: 'Персональное предложение успешно обновлено',
                      },
                  }).unwrap();
            handleBack();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const errObject = isAddingError || isUpdatingError;
        if (errObject) {
            const errors = normalizeErrorsObject(errObject?.data?.errors);
            const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
            form.setFields(formErrors);
        }
    }, [form, isAddingError, isUpdatingError]);

    useEffect(() => {
        if (promotion) {
            const formData: PromotionDto3 = {
                ...promotion,
                marketplace_store: promotion.marketplace_store.id,
                discount_value: +promotion.discount_value,
                period: [dayjs(promotion.start_date), dayjs(promotion.end_date)],
            };
            form.setFieldsValue(formData);
        }
    }, [form, promotion]);

    const initialValues = {
        type: PromotionTypeEnum2.Coupon,
        discount_type: DiscountTypeEnum.Percentage,
        discount_value: 10,
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Form
            form={form}
            name="personal-promotions-form"
            onFinish={onFinish}
            autoComplete="off"
            className="legal-info__form"
            initialValues={initialValues}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <PromotionSelectorCard
                    promotion={promotion}
                    isAddPersonalPromotion={isAddPersonalPromotion}
                    form={form}
                />
                <PromotionDetailsCard
                    selectedPromotionType={selectedPromotionType}
                    isAddPersonalPromotion={isAddPersonalPromotion}
                />
                <PromotionProductsTableCard
                    promotionId={promotion?.id || ''}
                    isAddPersonalPromotion={isAddPersonalPromotion}
                    setImportedFileName={(fileName) => {
                        form.setFieldValue('promotion_products_file_id', fileName);
                    }}
                />
            </Space>
            <Row
                justify="space-between"
                wrap={false}
                style={{ marginTop: 24, padding: breakpoints.md ? 0 : `0 ${paddingLG}px`, gap: paddingLG }}
            >
                <Button
                    onClick={handleBack}
                    style={{ width: breakpoints.md ? 'auto' : '100%' }}
                >
                    Отменить
                </Button>
                <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    loading={isAdding || isUpdating}
                >
                    {isAddPersonalPromotion ? 'Добавить' : 'Сохранить'}
                </Button>
            </Row>
        </Form>
    );
};
export default PersonalPromotionForm;
