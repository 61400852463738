import { SellerIntegrationsApi } from './SellerIntegrationsApi';

SellerIntegrationsApi.enhanceEndpoints({
    addTagTypes: ['CampaignsList', 'StoresList', 'Store', 'OzonIntegration', 'WBIntegration'],
    endpoints: {
        getAppPrivateSellerIntegrationOzonView: {
            providesTags: ['OzonIntegration'],
        },
        getExistMarketplaceIntegrationWithWildberries: {
            providesTags: ['WBIntegration'],
        },
        connectMarketplaceToWildberries: {
            invalidatesTags: ['CampaignsList', 'StoresList', 'WBIntegration', 'Store'],
        },
        connectMarketplaceToOzon: {
            invalidatesTags: ['CampaignsList', 'StoresList', 'OzonIntegration', 'Store'],
        },
        putAppPrivateSellerIntegrationOzonUpdate: {
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        SellerIntegrationsApi.util.invalidateTags([
                            'CampaignsList',
                            'StoresList',
                            'Store',
                            'OzonIntegration',
                        ]),
                    );
                } catch (err) {
                    console.error(err);
                }
            },
        },
        putAppPrivateSellerIntegrationWildberriesUpdate: {
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        SellerIntegrationsApi.util.invalidateTags([
                            'CampaignsList',
                            'StoresList',
                            'Store',
                            'OzonIntegration',
                        ]),
                    );
                } catch (err) {
                    console.error(err);
                }
            },
        },
    },
});

export * from './SellerIntegrationsApi';
