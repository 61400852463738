import { theme } from 'antd';

import { Form, Card, Typography, Radio } from '@Shared/ui';
import { PaymentTypeEnum3 } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { PaymentTypesMap } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';

const { Title } = Typography;

const PaymentTypeCard = ({ isFormDisabled }: { isFormDisabled: boolean }) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    return (
        <Card style={{ width: '100%' }}>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Способ оплаты блоггеру
            </Title>
            <Form.Item
                name={['barter_setting', 'payment_type']}
                // rules={[{ required: true, message: '' }, ...yupRules]}
                style={{ marginTop: 20 }}
            >
                <Radio.Group disabled={isFormDisabled}>
                    {Object.keys(PaymentTypesMap).map((paymentType) => (
                        <Radio
                            value={PaymentTypeEnum3[paymentType as keyof typeof PaymentTypeEnum3]}
                            key={paymentType}
                        >
                            {PaymentTypesMap[paymentType as keyof typeof PaymentTypesMap]}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </Card>
    );
};
export default PaymentTypeCard;
